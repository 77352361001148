import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromTwoStepVerification from './two-step-verification.reducer';

export const getTwoStepVerificationState = (state: fromApp.AppState) =>
  state.twoStepVerification;

export const getLoading = createSelector(
  getTwoStepVerificationState,
  (state: fromTwoStepVerification.twoStepVerificationReducerState) =>
    state.isLoading
);

export const getOtpLink = createSelector(
  getTwoStepVerificationState,
  (state: fromTwoStepVerification.twoStepVerificationReducerState) =>
    state.otpUrl
);

export const getUserByEmail = createSelector(
  getTwoStepVerificationState,
  (state: fromTwoStepVerification.twoStepVerificationReducerState) =>
    state.userEmail
);

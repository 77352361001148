import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as AppActions from '../app/app.actions';
import * as AuthActions from './auth.actions';
import * as EditorActions from '../editor/editor.actions';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericResponse, Notification } from '../../interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { USE_DEVELOPER_TOKEN } from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
import {
  ConfirmDeviceStatusEnum,
  RoleAccessLevelEnum,
  ThirdPartyTypeEnum,
} from '../../enums';
import { MsalService } from '@azure/msal-angular';
import { FlwmnSheet } from '../../services/sheet.service';
import { OtpVerificationComponent } from 'src/app/@auth/otp-verification/otp-verification.component';
import { MyProfileTwoFactorAuthenticationComponent } from 'src/app/@components/webapp/account-settings/my-profile/my-profile-two-factor-authentication/my-profile-two-factor-authentication.component';
// import { MyProfileTwoFactorAuthenticationComponent } from 'src/app/@components/webapp/account-settings/my-profile/my-profile-two-factor-authentication/my-profile-two-factor-authentication.component';
// import * as TwoStepVerificationActions from 'src/app/@core/stores/two-step-verification/two-step-verification.actions';

// import { authState } from 'rxfire/auth';

@Injectable()
export class AuthEffects {
  permissionsArray: string[] = [];

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private msalService: MsalService,
    private sheet: FlwmnSheet
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(AuthActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  parseJwt = (userToken: any) => {
    const base64Url = userToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  private formatPermissions = (
    decodedUserDetails: any,
    allPermissions: any[],
    subscriptionStatus: string
  ) => {
    // Check if subscription is active

    // (subscriptionStatus === 'Expired' ||
    //   subscriptionStatus === 'Cancelled' ||
    //   subscriptionStatus === 'ProcessingPayment' ||
    //   subscriptionStatus === 'PendingActivation' ||
    //   subscriptionStatus === '') &&
    // decodedUserDetails?.Role?.Subscriber?.SubscriberAccessLevel !== 1

    if (
      subscriptionStatus !== 'Active' &&
      subscriptionStatus !== 'FreeTrial' &&
      decodedUserDetails?.Role?.RoleAccessLevel !==
        RoleAccessLevelEnum.SuperAdmin
    ) {
      allPermissions = allPermissions?.filter((permission: any) => {
        return (
          permission?.RequiresSubscription === false && permission?.Status === 1
        );
      });
    }

    this.permissionsArray = [];

    allPermissions.forEach((permission) => {
      if (permission?.Status === 1) {
        this.permissionsArray.push(permission?.FeatureName); // only active permissions
      }

      // // if (el?.ParentFeatureId !== 0) {
      // if (decodedUserDetails?.Role?.Subscriber?.SubscriberAccessLevel === 1) {
      //   this.permissionsArray!.push(el?.FeatureName); // push both active and inactive permissions
      // } else {
      //   if (el?.Status === 1) {
      //     this.permissionsArray.push(el?.FeatureName); // only active permissions
      //   }
      // }
      // // }
    });
  };

  loginResponse = (loginData: any, authData: any) => {
    const accessToken = loginData.token.accessToken;
    const userToken = loginData.token.userToken;
    const exp = loginData.token.expiresIn * 60000 + new Date().getTime();
    const refreshToken = loginData.token.refreshToken;
    const data = { accessToken, userToken, exp, refreshToken };

    // if (authData.payload.keep_me_logged_in === true) {
    localStorage.setItem('OnyxDoc', JSON.stringify(data));
    // }

    // const decoded_token = JSON.parse(atob(userToken.split('.')[1]));

    const decoded_token = this.parseJwt(userToken);

    // const email = loginData.token.email;

    const user: any = {
      ...JSON.parse(decoded_token.UserEntity),
      ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
    };

    this.document.body.classList.remove('flwmn-default-theme');

    const user_branding: any = decoded_token?.Branding
      ? JSON.parse(decoded_token?.Branding)
      : null; //coming

    if (decoded_token.RolePermissions !== undefined) {
      this.formatPermissions(
        user,
        JSON.parse(decoded_token.RolePermissions),
        decoded_token.SubscriptionStatus
      );
    } else {
      // this.permissionsArray = []
    }

    if (authData.payload.instance === 'quick-sign-login') {
      this.store.dispatch(EditorActions.SaveUser({ payload: user }));

      return AuthActions.AuthenticateSuccess({
        payload: {
          user: user,
          permissionsArray: this.permissionsArray,
          instance: 'quick-sign-login',
          branding: user_branding,
        },
      });
    } else {
      return AuthActions.AuthenticateSuccess({
        payload: {
          user: user,
          permissionsArray: this.permissionsArray,
          branding: user_branding,
        },
      });
    }

    // return { user, user_branding };
  };

  initializeApp_DeveloperToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.InitializeApp_DeveloperToken),
      switchMap((authData) => {
        /**************If the OnyxDoc_auth does not exist in localStorage or has expired**************/
        const onyxDocAuthData: {
          bearer_token: string;
          expiryDate: number;
        } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

        // const detector: any = new Detector();

        if (
          onyxDocAuthData === null ||
          onyxDocAuthData?.expiryDate < new Date().getTime()
        ) {
          let deviceGuid = localStorage.getItem('deviceGuid');
          if (deviceGuid === undefined || deviceGuid === null) {
            deviceGuid = this.helperService.generateGUID();
            localStorage.setItem('deviceGuid', deviceGuid);
          }
          return this.http
            .post<any>(`${environment.onyxDocAuthUrl}/ApplicationAuth/login`, {
              ...environment.onyxDocAuth,
              browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
              deviceName: '',
            })
            .pipe(
              map((resData) => {
                if (resData.isSuccess === true) {
                  const bearer_token = resData.token.accessToken;

                  const expiryDate =
                    resData.token.expiresIn * 60000 + new Date().getTime();

                  localStorage.setItem(
                    'OnyxDoc_auth',
                    JSON.stringify({
                      bearer_token: bearer_token,
                      expiryDate: expiryDate,
                    })
                  );

                  this.store.dispatch(
                    AuthActions.UpdateDeveloperTokenStatus({
                      payload: { status: true },
                    })
                  );

                  return {
                    type: '[Auth] Successfully Received Developer Token',
                  };
                } else {
                  this.store.dispatch(
                    AuthActions.IsLoading({ payload: false })
                  );

                  return {
                    type: '[Auth] Failed To Receive Developer Token',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Receive Developer Token ${errorRes.message}`
                );
              })
            );
        } else {
          /*************************If the OnyxDoc_auth exists in localStorage*************************/
          const onyxDocAuthData: {
            bearer_token: string;
            expiryDate: number;
          } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

          /****If the OnyxDoc_auth exists in localStorage and the token has not expired****/
          if (onyxDocAuthData.expiryDate > new Date().getTime()) {
            this.store.dispatch(
              AuthActions.UpdateDeveloperTokenStatus({
                payload: {
                  status: true,
                },
              })
            );

            return of({
              type: '[Auth] Developer token still exists in localStorage',
            });
          } else {
            /****If the OnyxDoc_auth exists in localStorage but the token has expired****/
            this.store.dispatch(
              AuthActions.UpdateDeveloperTokenStatus({
                payload: { status: false },
              })
            );

            return of({
              type: '[Auth] Developer token has expired',
            });
          }
        }
      })
    );
  });

  initializeApp_AccessToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.InitializeApp_AccessToken),
      switchMap((authData) => {
        /****If OnyxDoc does not exist in localStorage****/
        if (localStorage.getItem('OnyxDoc') === null) {
          return of({
            type: '[Auth] Access token does not exist in localStorage',
          });
        } else {
          /*************************If OnyxDoc exists in localStorage*************************/
          const onyxDocData: {
            accessToken: string;
            userToken: string;
            exp: number;
          } = JSON.parse(localStorage.getItem('OnyxDoc')!);

          const onyxDocAuthData: {
            bearer_token: string;
            expiryDate: number;
          } = JSON.parse(localStorage.getItem('OnyxDoc_auth')!);

          /****If OnyxDoc exists in localStorage but the token has expired****/
          if (onyxDocData.exp < new Date().getTime()) {
            if (onyxDocData) {
              localStorage.removeItem('OnyxDoc');
            }

            if (onyxDocAuthData) {
              localStorage.removeItem('OnyxDoc_auth');
            }

            return of({
              type: '[Auth] Access token has expired and has been removed from localStorage',
            });
          } else {
            /****If the OnyxDoc exists in localStorage and the token has not expired****/
            this.store.dispatch(
              AuthActions.UpdateAccessTokenStatus({
                payload: { status: true },
              })
            );

            return of({
              type: '[Auth] Access token still exists in localStorage',
            });
          }
        }
      })
    );
  });

  refreshToken$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.RefreshToken),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/ApplicationAuth/RefreshToken`,
              {
                email: authState.user.Email,
                refreshToken: authData.payload.refreshToken,
              }
            )
            .pipe(
              map((resData) => {
                if (resData.isSuccess === true) {
                  this.authService.hasRefreshTokenBeenCalled = false;

                  const accessToken = resData.token.accessToken;
                  const userToken = resData.token.userToken;
                  const exp =
                    resData.token.expiresIn * 60000 + new Date().getTime();
                  const refreshToken = resData.token.refreshToken;
                  const data = { accessToken, userToken, exp, refreshToken };

                  localStorage.setItem('OnyxDoc', JSON.stringify(data));

                  this.authService.stopActivityMonitor();
                  this.authService.startActivityMonitor();

                  this.authService.hasRefreshTokenBeenCalled = false;

                  localStorage.removeItem('OnyxDoc_auth');

                  this.store.dispatch(
                    AuthActions.InitializeApp_DeveloperToken()
                  );

                  const decoded_token = this.parseJwt(userToken);

                  const user: any = {
                    ...JSON.parse(decoded_token.UserEntity),
                    ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
                  };

                  const user_branding: any = decoded_token?.Branding
                    ? JSON.parse(decoded_token?.Branding)
                    : null;

                  this.store.dispatch(
                    AuthActions.RefreshTokenSuccess({
                      payload: {
                        user: user,
                        permissionsArray: this.permissionsArray,
                        branding: user_branding,
                      },
                    })
                  );

                  // this.store.dispatch({
                  //   type: '[Auth] Refresh Token Successfully',
                  // });
                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Auth] Failed To Refresh Token',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Refresh Token ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  refreshTokenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RefreshTokenSuccess),
      map((authData) => {
        // Function to apply user preferred theme
        const theme_color = authData.payload?.branding?.ThemeColor
          ? authData.payload?.branding?.ThemeColor.toLowerCase()
          : null;

        // Theme test
        if (theme_color) {
          this.document.body.classList.add(theme_color);
        } else {
          this.document.body.classList.add('theme1');
        }

        this.store.dispatch(
          AuthActions.UpdateAccessTokenStatus({ payload: { status: true } })
        );

        return AuthActions.IsLoading({ payload: false });
      })
    );
  });

  // authRegister$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(AuthActions.RegisterStart),
  //       switchMap((authData) => {
  //         return this.http
  //           .post<any>(
  //             `${environment.onyxDocAuthUrl}/Subscribers/signup`,
  //             authData.payload
  //           )
  //           .pipe(
  //             map((resData) => {
  //               this.store.dispatch(AuthActions.IsLoading({ payload: false }));

  //               if (resData.succeeded === true) {
  //                 this.store.dispatch({
  //                   type: '[Auth] Create Organization Was Successful',
  //                 });

  //                 return resData;
  //               } else {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openSnackBar(
  //                   notification,
  //                   'flwmn-notification-error'
  //                 );

  //                 this.store.dispatch({
  //                   type: '[Auth] Create Organization Failed',
  //                 });

  //                 return resData;
  //               }
  //             }),
  //             catchError((errorRes) => {
  //               return this.handleCatchError(
  //                 errorRes,
  //                 `[Auth][CatchError] Failed To Create Organization ${errorRes.message}`
  //               );
  //             })
  //           );
  //       }),
  //       share()
  //     );
  //   },
  //   { dispatch: false }
  // );

  authRegister$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.RegisterStart),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/signupandsubscribe`,
              {
                ...authData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Registration Was Successful',
                  });

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Register',
                  });

                  if (
                    authData.payload.subscriber.thirdPartyType ===
                    ThirdPartyTypeEnum.Microsoft
                  ) {
                    const notification: Notification = {
                      state: 'warning',
                      message: `We've run into a problem while signing up with your Azure AD account. 
                      To resolve this, we'll guide you to Azure for a quick logout, 
                      after which you can restart the process.`,
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-warning',
                      true
                    );

                    const timeout = setTimeout(() => {
                      /********* For Azure AD *********/
                      if (
                        this.msalService.instance.getAllAccounts().length >
                          0 ===
                        true
                      ) {
                        this.msalService.logoutRedirect({
                          postLogoutRedirectUri: '/register',
                        });
                      }
                      /********************************/
                      clearTimeout(timeout);
                    }, 10000);
                  }
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Register ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  authStartRegister$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.StartRegistration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocSubscriptionUrl}/Subscriptions/signupandsubscribe`,
              {
                ...authData.payload,
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Start Registration Was Successful',
                  });

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Start Register',
                  });

                  if (
                    authData.payload.subscriber.thirdPartyType ===
                    ThirdPartyTypeEnum.Microsoft
                  ) {
                    const notification: Notification = {
                      state: 'warning',
                      message: `We've run into a problem while signing up with your Azure AD account. 
                      To resolve this, we'll guide you to Azure for a quick logout, 
                      after which you can restart the process.`,
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-warning',
                      true
                    );

                    const timeout = setTimeout(() => {
                      /********* For Azure AD *********/
                      if (
                        this.msalService.instance.getAllAccounts().length >
                          0 ===
                        true
                      ) {
                        this.msalService.logoutRedirect({
                          postLogoutRedirectUri: '/register',
                        });
                      }
                      /********************************/
                      clearTimeout(timeout);
                    }, 10000);
                  }
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Register ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  completeRegistration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.CompleteRegistration),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Subscribers/updatesignup`,
              {
                ...authData.payload,
              },
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                /*    if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Complete Registration Was Successful',
                  });

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Complete Register',
                  });
                } */
                // conso;

                if (resData.succeeded) {
                  if (resData.entity.isSuccess === true) {
                    if (resData.entity.isDeviceVerified === true) {
                      if (
                        (resData.entity.isTwoFAEnabled &&
                          resData.entity.hasAuthenticatorApp === true) ||
                        (resData.entity.isTwoFAEnabled &&
                          resData.entity.hasEmailTwoFA === true)
                      ) {
                        this.dialog.open(OtpVerificationComponent, {
                          data: {
                            email: authData.payload.email,
                            ipAddress: authData.payload.ipAddress,
                          },
                          disableClose: true,
                          autoFocus: false,
                          panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
                          // backdropClass: 'flwmn-dialog-backdrop-blur',
                          backdropClass: 'flwmn-dialog-backdrop',
                        });
                      }
                      const accessToken = resData.entity.token.accessToken;
                      const userToken = resData.entity.token.userToken;
                      const exp =
                        resData.entity.token.expiresIn * 60000 +
                        new Date().getTime();
                      const refreshToken = resData.entity.token.refreshToken;
                      const data = {
                        accessToken,
                        userToken,
                        exp,
                        refreshToken,
                      };

                      localStorage.setItem('OnyxDoc', JSON.stringify(data));

                      // const decoded_token = JSON.parse(atob(userToken.split('.')[1]));

                      const decoded_token = this.parseJwt(userToken);

                      // const email = resData.token.email;

                      const user: any = {
                        ...JSON.parse(decoded_token.UserEntity),
                        ApiClientId: JSON.parse(decoded_token?.ApiClient)
                          ?.ClientId,
                      };

                      this.document.body.classList.remove(
                        'flwmn-default-theme'
                      );

                      const user_branding: any = decoded_token?.Branding
                        ? JSON.parse(decoded_token?.Branding)
                        : null; //coming

                      if (decoded_token.RolePermissions !== undefined) {
                        this.formatPermissions(
                          user,
                          JSON.parse(decoded_token.RolePermissions),
                          decoded_token.SubscriptionStatus
                        );
                      } else {
                        // this.permissionsArray = []
                      }

                      this.store.dispatch(
                        EditorActions.SaveUser({ payload: user })
                      );

                      this.store.dispatch(
                        AuthActions.AuthenticateSuccess({
                          payload: {
                            user: user,
                            permissionsArray: this.permissionsArray,
                            instance: 'quick-sign-login',
                            branding: user_branding,
                          },
                        })
                      );
                    } else {
                      this.store.dispatch(
                        AuthActions.IsLoading({ payload: false })
                      );

                      this.store.dispatch(
                        AuthActions.ToVerifyDevice({
                          payload: {
                            deviceGuid: resData.entity.deviceGuid,
                            verifyStatus: true,
                          },
                        })
                      );

                      const notification: Notification = {
                        state: 'success',
                        message:
                          resData.entity.message || resData?.messages?.[0],
                      };

                      this.notificationService.openNotification(
                        notification,
                        'flwmn-notification-success'
                      );
                    }
                  } else {
                    this.store.dispatch(
                      AuthActions.IsLoading({ payload: false })
                    );

                    if (
                      resData.entity.message !== 'User does not exist' &&
                      resData.entity.message !==
                        'You are not verified. Please check your mail to verify your account'
                    ) {
                      const notification: Notification = {
                        state: 'error',
                        message: resData.entity.message || resData.messages[0],
                      };

                      this.notificationService.openNotification(
                        notification,
                        'flwmn-notification-error'
                      );
                    }

                    if (resData?.message === 'User does not exist') {
                      const notification: Notification = {
                        state: 'error',
                        message: resData.message || resData.messages[0],
                      };

                      this.notificationService.openNotification(
                        notification,
                        'flwmn-notification-error',
                        true
                      );
                    }

                    if (
                      resData?.message ===
                      'You are not verified. Please check your mail to verify your account'
                    ) {
                      const notification: Notification = {
                        state: 'error',
                        message: resData.message || resData.messages[0],
                      };

                      this.notificationService.openNotification(
                        notification,
                        'flwmn-notification-error',
                        true
                      );

                      // this.router.navigate(['login/issue'], {
                      //   queryParams: {
                      //     issueType: 'email not verified',
                      //     email: authData.payload.email,
                      //     returnUrl: this.router.url,
                      //   },
                      // });
                    }

                    return {
                      type: `[Auth] Failed To Login`,
                    };
                  }
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                return { type: '[Auth] Complete Registration failed' };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Complete Registration ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  authRegisterWithInvite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.RegisterWithInvite),
      switchMap((authData) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Users/editinviteduser`,
            authData.payload
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));
              if (resData.succeeded === true) {
                this.router.navigate([
                  `register/welcome-onboard/${authData.payload.email}`,
                ]);

                return {
                  type: '[Auth] Register With Invitee Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Auth] Register With Invitee Failed' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Register With Invitee ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  authRegisterWithEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.SignupWithEmail),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocAuthUrl}/Subscribers/signupwithemail`,
              {
                ...authData.payload,
                deviceType: 0,
              },
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Registration Was Successful',
                  });

                  this.router.navigate([
                    `register/welcome-onboard/${authData.payload.email}`,
                  ]);
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Register',
                  });

                  if (
                    authData.payload.thirdPartyType ===
                    ThirdPartyTypeEnum.Microsoft
                  ) {
                    const notification: Notification = {
                      state: 'warning',
                      message: `We've run into a problem while signing up with your Azure AD account. 
                      To resolve this, we'll guide you to Azure for a quick logout, 
                      after which you can restart the process.`,
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-warning',
                      true
                    );

                    const timeout = setTimeout(() => {
                      /********* For Azure AD *********/
                      if (
                        this.msalService.instance.getAllAccounts().length >
                          0 ===
                        true
                      ) {
                        this.msalService.logoutRedirect({
                          postLogoutRedirectUri: '/register',
                        });
                      }
                      /********************************/
                      clearTimeout(timeout);
                    }, 10000);
                  }
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Register ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  checkUpDomainName$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.CheckUpDomainName),
        switchMap((authData) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Users/checkupdomainname`,
              {
                ...authData.payload,
              },
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Check Up Domain Name Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Check Up Domain Name',
                  });
                }

                return {
                  resData: resData,
                  alternatePayload: authData?.alternatePayload,
                };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Check Up Domain Name ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  checkEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.CheckEmail),
        switchMap((authData) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Subscribers/checkemail`,
              {
                contactEmail: authData.payload.contactEmail,
              },
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Check Email Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  if (authData.defaultErrorMessage === undefined) {
                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-error'
                    );
                  }

                  this.store.dispatch({
                    type: '[Auth] Failed To Check Email',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Check Email ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  checkOldRecipient$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.CheckOldRecipient),
        switchMap((authData) => {
          return this.http
            .get<any>(
              `${environment.onyxDocDocumentUrl}/Recipients/getrecipientbyemail/${authData.payload.email}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Auth] Check Old Recipient Was Successful',
                  });

                  return resData;
                } else {
                  this.store.dispatch({
                    type: '[Auth] Failed To Check Old Recipient',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Check Old Recipient ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // authLogin$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AuthActions.LoginStart),
  //     switchMap((authData) => {
  //       return this.http
  //         .post(`${environment.onyxDocAuthUrl}/Authentication/login`, {
  //           email: authData.payload.email,
  //           password: authData.payload.password,
  //           ipAddress: authData.payload.ipAddress,
  //           location: authData.payload.location,
  //           deviceName: authData.payload.deviceName,
  //           deviceId: authData.payload.deviceId,
  //           browser: authData.payload.browser,
  //           deviceType: 0,
  //         })
  //         .pipe(
  //           map((resData: any) => {
  //             if (resData.isSuccess === true) {
  //               if (resData.isDeviceVerified === true) {
  //                 const accessToken = resData.token.accessToken;
  //                 const userToken = resData.token.userToken;
  //                 const exp =
  //                   resData.token.expiresIn * 60000 + new Date().getTime();
  //                 const refreshToken = resData.token.refreshToken;
  //                 const data = { accessToken, userToken, exp, refreshToken };

  //                 if (authData.payload.keep_me_logged_in === true) {
  //                   localStorage.setItem('OnyxDoc', JSON.stringify(data));
  //                 }

  //                 // const decoded_token = JSON.parse(atob(userToken.split('.')[1]));

  //                 const decoded_token = this.parseJwt(userToken);

  //                 const user: any = {
  //                   ...JSON.parse(decoded_token.UserEntity),
  //                   ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
  //                 };

  //                 this.document.body.classList.remove('flwmn-default-theme');

  //                 const user_branding: any = decoded_token?.Branding
  //                   ? JSON.parse(decoded_token?.Branding)
  //                   : null; //coming

  //                 if (decoded_token.RolePermissions !== undefined) {
  //                   this.formatPermissions(
  //                     user,
  //                     JSON.parse(decoded_token.RolePermissions),
  //                     decoded_token.SubscriptionStatus
  //                   );
  //                 } else {
  //                   // this.permissionsArray = []
  //                 }

  //                 if (authData.payload.instance === 'quick-sign-login') {
  //                   this.store.dispatch(
  //                     EditorActions.SaveUser({ payload: user })
  //                   );

  //                   return AuthActions.AuthenticateSuccess({
  //                     payload: {
  //                       user: user,
  //                       permissionsArray: this.permissionsArray,
  //                       instance: 'quick-sign-login',
  //                       branding: user_branding,
  //                     },
  //                   });
  //                 } else {
  //                   return AuthActions.AuthenticateSuccess({
  //                     payload: {
  //                       user: user,
  //                       permissionsArray: this.permissionsArray,
  //                       branding: user_branding,
  //                     },
  //                   });
  //                 }
  //               } else {
  //                 this.store.dispatch(
  //                   AuthActions.IsLoading({ payload: false })
  //                 );

  //                 this.store.dispatch(
  //                   AuthActions.ToVerifyDevice({
  //                     payload: {
  //                       deviceGuid: resData.deviceGuid,
  //                       verifyStatus: true,
  //                     },
  //                   })
  //                 );

  //                 const notification: Notification = {
  //                   state: 'success',
  //                   message: resData?.message || resData?.messages?.[0],
  //                 };

  //                 this.notificationService.openNotification(
  //                   notification,
  //                   'flwmn-notification-success'
  //                 );

  //                 return {
  //                   type: `[Auth] User Device Is Not Verified`,
  //                 };
  //               }
  //             } else {
  //               this.store.dispatch(AuthActions.IsLoading({ payload: false }));

  //               if (
  //                 resData?.message !== 'User does not exist' &&
  //                 resData?.message !==
  //                   'You are not verified. Please check your mail to verify your account'
  //               ) {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openNotification(
  //                   notification,
  //                   'flwmn-notification-error'
  //                 );
  //               }

  //               if (resData?.message === 'User does not exist') {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openNotification(
  //                   notification,
  //                   'flwmn-notification-error',
  //                   true
  //                 );
  //               }

  //               if (
  //                 resData?.message ===
  //                 'You are not verified. Please check your mail to verify your account'
  //               ) {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openNotification(
  //                   notification,
  //                   'flwmn-notification-error',
  //                   true
  //                 );

  //                 // this.router.navigate(['login/issue'], {
  //                 //   queryParams: {
  //                 //     issueType: 'email not verified',
  //                 //     email: authData.payload.email,
  //                 //     returnUrl: this.router.url,
  //                 //   },
  //                 // });
  //               }

  //               return {
  //                 type: `[Auth] Failed To Login`,
  //               };
  //             }
  //           }),
  //           catchError((errorRes) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Auth][CatchError] Failed To Login ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   );
  // });

  authLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.LoginStart),
      switchMap((authData) => {
        return this.http
          .post(`${environment.onyxDocAuthUrl}/Authentication/login`, {
            email: authData.payload.email,
            password: authData.payload.password,
            ipAddress: authData.payload.ipAddress,
            location: authData.payload.location,
            deviceName: authData.payload.deviceName,
            deviceId: authData.payload.deviceId,
            browser: authData.payload.browser,
            deviceType: 0,
          })
          .pipe(
            map((resData: any) => {
              if (resData.isSuccess === true) {
                if (resData.isDeviceVerified === true) {
                  // Check for your specific condition
                  // if (
                  //   resData.isTwoFAEnabled === true &&
                  //   resData.hasEmailTwoFA === false &&
                  //   resData.hasAuthenticatorApp === false
                  // ) {
                  //   // this.store.dispatch(
                  //   //   TwoStepVerificationActions.GetUserByEmail({
                  //   //     payload: {
                  //   //       email: user.Email,
                  //   //       userId: user.UserId,
                  //   //     },
                  //   //   })
                  //   // );

                  //   // Open your modal here
                  //   this.dialog.open(
                  //     MyProfileTwoFactorAuthenticationComponent,
                  //     {
                  //       data: {
                  //         email: authData.payload.email,
                  //         ipAddress: authData.payload.ipAddress,
                  //       },
                  //       disableClose: true,
                  //       autoFocus: false,
                  //       panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
                  //       backdropClass: 'flwmn-dialog-backdrop',
                  //     }
                  //   );

                  //   return AuthActions.GetUserTwoFADetails({
                  //     payload: {
                  //       email: authData.payload.email,
                  //     },
                  //   });
                  // } else

                  if (
                    (resData.isTwoFAEnabled &&
                      resData.hasAuthenticatorApp === true) ||
                    (resData.isTwoFAEnabled && resData.hasEmailTwoFA === true)
                  ) {
                    this.dialog.open(OtpVerificationComponent, {
                      data: {
                        email: authData.payload.email,
                        ipAddress: authData.payload.ipAddress,
                      },
                      disableClose: true,
                      autoFocus: false,
                      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
                      // backdropClass: 'flwmn-dialog-backdrop-blur',
                      backdropClass: 'flwmn-dialog-backdrop',
                    });

                    // this.store.dispatch(
                    //   AuthActions.GetUserTwoFADetails({
                    //     payload: {
                    //       email: authData.payload.email,
                    //     },
                    //   })
                    // );
                    return AuthActions.GetUserTwoFADetails({
                      payload: {
                        email: authData.payload.email,
                      },
                    });
                  } else {
                    return this.loginResponse(resData, authData);

                    // if (authData.payload.instance === 'quick-sign-login') {
                    //   this.store.dispatch(
                    //     EditorActions.SaveUser({ payload: user })
                    //   );

                    //   return AuthActions.AuthenticateSuccess({
                    //     payload: {
                    //       user: user,
                    //       permissionsArray: this.permissionsArray,
                    //       instance: 'quick-sign-login',
                    //       branding: user_branding,
                    //     },
                    //   });
                    // } else {
                    //   return AuthActions.AuthenticateSuccess({
                    //     payload: {
                    //       user: user,
                    //       permissionsArray: this.permissionsArray,
                    //       branding: user_branding,
                    //     },
                    //   });
                    // }
                  }
                } else {
                  this.store.dispatch(
                    AuthActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    AuthActions.ToVerifyDevice({
                      payload: {
                        deviceGuid: resData.deviceGuid,
                        verifyStatus: true,
                      },
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: resData?.message || resData?.messages?.[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  return {
                    type: `[Auth] User Device Is Not Verified`,
                  };
                }
              } else {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (
                  resData?.message !== 'User does not exist' &&
                  resData?.message !==
                    'You are not verified. Please check your mail to verify your account'
                ) {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );
                }

                if (resData?.message === 'User does not exist') {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );
                }

                if (
                  resData?.message ===
                  'You are not verified. Please check your mail to verify your account'
                ) {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  // this.router.navigate(['login/issue'], {
                  //   queryParams: {
                  //     issueType: 'email not verified',
                  //     email: authData.payload.email,
                  //     returnUrl: this.router.url,
                  //   },
                  // });
                }

                return {
                  type: `[Auth] Failed To Login`,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Login ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  authLoginWithThirdParty$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.LoginWithThirdParty),
        switchMap((authData) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Authentication/loginwiththirdparty`,
              {
                email: authData.payload.email,
                thirdPartyType: authData.payload.thirdPartyType,
                browser: authData.payload.browser, // Include browser in the request
                deviceType: authData.payload.deviceType,
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.isSuccess === true) {
                  const accessToken = resData.token.accessToken;

                  const userToken = resData.token.userToken;

                  const exp =
                    resData.token.expiresIn * 60000 + new Date().getTime();

                  const refreshToken = resData.token.refreshToken;

                  const data = { accessToken, userToken, exp, refreshToken };

                  if (authData.payload.keep_me_logged_in === true) {
                    localStorage.setItem('OnyxDoc', JSON.stringify(data));
                  }

                  if (resData.isDeviceVerified === false) {
                    this.store.dispatch(
                      AuthActions.IsLoading({ payload: false })
                    );

                    this.store.dispatch(
                      AuthActions.ToVerifyDevice({
                        payload: {
                          deviceGuid: resData.deviceGuid,
                          verifyStatus: true,
                        },
                      })
                    );

                    const notification: Notification = {
                      state: 'success',
                      message: resData?.message || resData?.messages?.[0],
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );
                  }

                  const decoded_token = this.parseJwt(userToken);

                  const user: any = {
                    ...JSON.parse(decoded_token.UserEntity),
                    ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
                  };

                  const user_branding: any = JSON.parse(decoded_token.Branding);

                  if (decoded_token.RolePermissions !== undefined) {
                    this.formatPermissions(
                      user,
                      JSON.parse(decoded_token.RolePermissions),
                      decoded_token.SubscriptionStatus
                    );
                  } else {
                    // this.permissionsArray = []
                  }

                  this.store.dispatch(
                    AuthActions.AuthenticateSuccess({
                      payload: {
                        user: user,
                        permissionsArray: this.permissionsArray,
                        branding: user_branding,
                      },
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: `[Auth] Failed To Login With Third Party`,
                  });

                  if (
                    authData.payload.thirdPartyType ===
                    ThirdPartyTypeEnum.Microsoft
                  ) {
                    const notification: Notification = {
                      state: 'warning',
                      message: `We'll guide you to Azure for a quick logout, 
                      after which you can restart the process.`,
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-warning',
                      true
                    );

                    const timeout = setTimeout(() => {
                      /********* For Azure AD *********/
                      if (
                        this.msalService.instance.getAllAccounts().length >
                          0 ===
                        true
                      ) {
                        this.msalService.logoutRedirect({
                          postLogoutRedirectUri: '/login',
                        });
                      }
                      /********************************/
                      clearTimeout(timeout);
                    }, 10000);
                  }
                }

                return {
                  resData: resData,
                  payload: authData.payload,
                };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To login With Third Party ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  authLinkedInSignUp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.LinkedInSignUp),
      switchMap(() => {
        return this.http
          .get<any>(
            `${environment.onyxDocAuthUrl}/Authentication/linkedinsignup`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                if (localStorage.getItem('linkedInActionState') !== null) {
                  localStorage.removeItem('linkedInActionState');
                }

                localStorage.setItem(
                  'linkedInActionState',
                  resData.entity.split('state=')[1].split('&redirect_uri')[0]
                );

                window.location.href = resData.entity;

                return {
                  type: '[Auth] Sign Up with LinkedIn Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Sign Up with LinkedIn',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Sign Up With LinkedIn ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  linkedInSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.LinkedInSignIn),
      switchMap((authData) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Authentication/loginwithlinkedin`,
            { ...authData.payload },
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                AuthActions.IsLoading({
                  payload: true,
                })
              );

              if (resData.isSuccess) {
                const accessToken = resData.token.accessToken;
                const userToken = resData.token.userToken;
                const exp =
                  resData.token.expiresIn * 60000 + new Date().getTime();
                const refreshToken = resData.token.refreshToken;
                const data = { accessToken, userToken, exp, refreshToken };

                if (authData.payload.keep_me_logged_in) {
                  localStorage.setItem('OnyxDoc', JSON.stringify(data));
                }

                if (resData.isDeviceVerified === false) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                }

                const parseJwt = () => {
                  const base64Url = userToken.split('.')[1];
                  const base64 = base64Url
                    .replace(/-/g, '+')
                    .replace(/_/g, '/');
                  const jsonPayload = decodeURIComponent(
                    atob(base64)
                      .split('')
                      .map(function (c) {
                        return (
                          '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                      })
                      .join('')
                  );

                  return JSON.parse(jsonPayload);
                };

                const decoded_token = parseJwt();

                const user: any = {
                  ...JSON.parse(decoded_token.UserEntity),
                  ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
                };

                const user_branding: any = JSON.parse(decoded_token.Branding);

                this.router.navigate(['/app/dashboard']);

                this.store.dispatch(
                  AuthActions.IsLoading({
                    payload: false,
                  })
                );

                if (decoded_token.RolePermissions !== undefined) {
                  this.formatPermissions(
                    user,
                    JSON.parse(decoded_token.RolePermissions),
                    decoded_token.SubscriptionStatus
                  );
                } else {
                  // this.permissionsArray = []
                }

                if (localStorage.getItem('linkedInAction') !== null) {
                  localStorage.removeItem('linkedInAction');
                }

                return AuthActions.AuthenticateSuccess({
                  payload: {
                    user: user,
                    permissionsArray: this.permissionsArray,
                    branding: user_branding,
                  },
                });
              } else {
                this.store.dispatch(
                  AuthActions.IsLoading({
                    payload: false,
                  })
                );

                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: `[Auth] Auth LinkedIn Sign In Failed`,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Auth LinkedIn Sign In Failed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  authLinkedInVerification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.LinkedInVerification),
        switchMap((authData) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Authentication/linkedinauthorization`,
              authData.payload
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const email = resData?.entity?.user?.email;

                  if (email) {
                    this.router.navigate([`register/welcome-onboard/${email}`]);
                  }

                  this.store.dispatch({
                    type: '[Auth] Create Subscriber Account Was Successful',
                  });

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Create Subscriber Account Failed',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] LinkedIn Verification Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  authSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.AuthenticateSuccess),
        map((authData) => {
          // Function to apply user preferred theme
          const theme_color = authData.payload?.branding?.ThemeColor
            ? authData.payload?.branding?.ThemeColor.toLowerCase()
            : null;

          // Theme test
          if (theme_color) {
            this.document.body.classList.add(theme_color);
          } else {
            this.document.body.classList.add('theme1');
          }

          // Check if 2FA is enabled opens myProfileTwoFactorAuthentication
          if (
            authData.payload.user.IsTwoFactorEnabled === true &&
            authData.payload.user.HasEmailTwoFA === false &&
            authData.payload.user.HasAuthenticatorApp === false
          ) {
            this.dialog.open(MyProfileTwoFactorAuthenticationComponent, {
              data: {
                email: authData.payload.user?.email,
              },
              maxWidth: '100%',
              disableClose: true,
              autoFocus: false,
              panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
              backdropClass: 'flwmn-dialog-backdrop',
            });
          }

          /*****Only linkedin sign in will fall in this condition*****/
          if (this.router.url.split('?')[0] === '/linkedInResponse') {
            this.router.navigate(['/app/dashboard']);
          }
          /**********************************************************/

          this.store.dispatch(
            AuthActions.UpdateAccessTokenStatus({ payload: { status: true } })
          );

          const returnUrl =
            this.activatedRoute.snapshot.queryParams['returnUrl'];

          if (returnUrl !== undefined) {
            this.router.navigateByUrl(returnUrl);
            // } else if (this.router.url === '/login') {
          } else if (this.router.url.includes('redirectToBilling=true')) {
            this.router.navigate(['/app/account-settings/billing']);
          } else if (this.router.url.includes('/login')) {
            this.router.navigate(['/app/dashboard']);
          } else if (this.router.url.includes('signup-verify')) {
            this.router.navigateByUrl('/app/dashboard?startTour=true', {
              replaceUrl: true,
            });
          }

          // this.authService.routeUserAfterSignIn();
          this.authService.startActivityMonitor();

          this.store.dispatch(AuthActions.IsLoading({ payload: false }));

          this.notificationService.closeAllNotifications();

          if (authData.payload.instance === 'quick-sign-login') {
            return true;
          } else {
            return false;
          }
        }),
        share()
      );
    },
    { dispatch: false }
  );

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.AutoLogin),
      map(() => {
        const onyxData: {
          accessToken: string;
          userToken: string;
          exp: number;
        } = JSON.parse(localStorage.getItem('OnyxDoc')!);

        if (
          onyxData &&
          // onyxAuthData &&
          new Date().getTime() < onyxData.exp
        ) {
          const userToken = onyxData.userToken;
          // const decoded_token = JSON.parse(atob(userToken.split('.')[1]));
          const decoded_token = this.parseJwt(userToken);

          const user: any = {
            ...JSON.parse(decoded_token.UserEntity),
            ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
          };

          const user_branding: any = JSON.parse(decoded_token?.Branding);

          if (decoded_token.RolePermissions !== undefined) {
            this.formatPermissions(
              user,
              JSON.parse(decoded_token.RolePermissions),
              decoded_token.SubscriptionStatus
            );
          }

          return AuthActions.AuthenticateSuccess({
            payload: {
              user: user,
              permissionsArray: this.permissionsArray,
              branding: user_branding,
            },
          });
        } else {
          return { type: '[Auth] No User Is Logged In' };
        }
      }),
      catchError((errorRes) => {
        return this.handleCatchError(
          errorRes,
          `[Auth][CatchError] Failed To Auto Login ${errorRes.message}`
        );
      })
    );
  });

  authLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.Logout),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        let deviceGuid = localStorage.getItem('deviceGuid');
        if (deviceGuid === undefined || deviceGuid === null) {
          deviceGuid = this.helperService.generateGUID();
          localStorage.setItem('deviceGuid', deviceGuid);
        }

        return this.http
          .post<any>(`${environment.onyxDocAuthUrl}/Authentication/logout`, {
            email: authState?.user?.Email,
            browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
            deviceName: '',
          })
          .pipe(
            map((resData) => {
              if (resData.succeeded) {
                this.authService.hasSessionLogoutBeenCalled = false;

                this.authService.stopActivityMonitor();

                localStorage.removeItem('OnyxDoc');

                localStorage.removeItem('OnyxDoc_auth');

                this.store.dispatch(
                  AuthActions.UpdateDeveloperTokenStatus({
                    payload: { status: false },
                  })
                );

                this.store.dispatch(
                  AuthActions.UpdateAccessTokenStatus({
                    payload: { status: false },
                  })
                );

                // removing user brand and then using the default theme, which is theme1
                this.document.body.className = '';
                this.document.body.classList.add('flwmn-default-theme');

                // Closing all currently opened dialogs just incase any is open
                this.dialog.closeAll();
                this.sheet.closeAll();

                /***************************Reset all stores***************************/
                this.store.dispatch(AppActions.ResetAllStores());
                /**********************************************************************/

                if (!authData.payload.isInstance2) {
                  this.router.navigate(['/login'], {
                    state: { bypassGuard: true },
                  });

                  /********* For Azure AD *********/
                  if (
                    this.msalService.instance.getAllAccounts().length > 0 ===
                    true
                  ) {
                    this.msalService.logoutRedirect({
                      postLogoutRedirectUri: '/login',
                    });
                  }
                  /********************************/
                }

                // this.authService.clearLogoutTimer();

                const notification: Notification = {
                  state: 'success',
                  message: 'You have been logged out successfully.',
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return { type: '[Auth] Logout Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Auth] Failed To Logout' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Auto Logout ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ForgotPassword),
      switchMap((authData) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Authentication/forgotpassword`,
            authData.payload
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                return { type: '[Auth] Forgot password Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return { type: '[Auth] Forgot Password Failed' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Forgot Password Failed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ResetPassword),
      switchMap((authData) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Authentication/resetpassword`,
            authData.payload
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: `Congratulations, your password has successfully been reset`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/login']);

                return { type: '[Auth] reset Password Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[Auth] Failed To Reset Password' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Reset Password ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  changePassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.ChangePassword),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post(
              `${environment.onyxDocAuthUrl}/Authentication/changepassword`,
              authData.payload
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Auth] Change Password Was Successful',
                  });

                  if (authState.user.Email === authData.payload.email) {
                    const notification: Notification = {
                      state: 'success',
                      message:
                        'Your password has successfully been updated. Logging you out now.',
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );

                    setTimeout(() => {
                      this.store.dispatch(AuthActions.Logout({ payload: {} }));
                    }, 5000);
                  } else {
                    const notification: Notification = {
                      state: 'success',
                      message: `Password has successfully been updated`,
                    };

                    this.notificationService.openNotification(
                      notification,
                      'flwmn-notification-success'
                    );
                  }

                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth Failed To Change Password',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Change Password ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  confirmEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ConfirmEmail),
      switchMap((authData) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Users/emailconfirmation`,
            // `${environment.onyxDocAuthUrl}/Users/emailconfirmation?Email=${authData.payload.email}&Token=${authData.payload.token}`,
            { email: authData.payload.email, token: authData.payload.token },
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                  // message: `Email confirmation was successful. Please login`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                this.router.navigate(['/login']);

                return { type: '[Auth] Email Confirmation Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return { type: '[Auth] Email Confirmation Failed' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Email Confirmation Failed ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  joinExistingOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.JoinExistingOrganization),
      switchMap((authData) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Users/joinexistingorganization`,
            authData.payload
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                this.router.navigate(['/login']);

                return {
                  type: '[Auth] Join Existing Organization Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Join Existing Organization',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Join Existing Organization ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  documentBranding$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.DocumentBranding),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandindData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Brandings/createbranding`,
              {
                ...documentBrandindData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  // Change theme on fly :)
                  this.document.body.className = '';
                  this.document.body.classList.add(resData.entity.themeColor);

                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Document branding saved successfully, re-login to effect the changes',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Auth] Document Branding Created Successfully',
                  });
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Document Branding Failed',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Document Branding Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getDocumentBranding$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.GetDocumentBranding),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentBrandingData, authState]) => {
        return this.http
          .get<any>(
            `${environment.onyxDocAuthUrl}/Brandings/getbrandingbysubscriberid/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return AuthActions.SaveDocumentBranding({
                  payload: resData?.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Get Document Branding',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Get Document Branding ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  updateDocumentBranding$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.UpdateDocumentBranding),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandindData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Brandings/updatebranding/${documentBrandindData.payload.id}`,
              {
                ...documentBrandindData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )

            .pipe(
              map((resData) => {
                this.document.body.className = '';
                this.document.body.classList.add(resData.entity.themeColor);

                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Document branding saved successfully,  re-login to effect the changes',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Auth] Document branding Create save successfully',
                  });
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Update Document Branding',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Update Document Branding ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createSystemSetting$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.CreateSystemSetting),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandindData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/Brandings/createsystemsetting`,
              {
                ...documentBrandindData.payload,
                subscriberId: authState.user.SubscriberId,
                userId: authState.user.UserId,
              }
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'System setting saved successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Auth] Create System Setting Was Successful',
                  });
                  return resData;
                } else {
                  this.store.dispatch(
                    AuthActions.IsLoading({ payload: false })
                  );

                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Create System Setting',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Create System Setting ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteExpirationReminder$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.DeleteExpirationReminder),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandindData, authState]) => {
          return this.http
            .delete<any>(
              `${environment.onyxDocAuthUrl}/Brandings/deleteExpiryReminder/${documentBrandindData.payload.expirationId}/${authState.user.SubscriberId}/${documentBrandindData.payload.systemSettingId}/${authState.user.UserId}`
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: 'Expiration reminder was deleted successfully',
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Auth] Delete Expriation Reminder Was Successful',
                  });
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Delete Expiration Reminder',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Delete Expiration Reminder ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  // getSystenSetting$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(AuthActions.GetSystenSetting),
  //       withLatestFrom(this.store.select('auth')),
  //       switchMap(([documentBrandingData, authState]) => {
  //         return this.http
  //           .get<any>(
  //             `${environment.onyxDocAuthUrl}/Brandings/getsystemsettingbysubscriberid/${authState.user.SubscriberId}/${authState.user.UserId}`
  //           )

  //           .pipe(
  //             map((resData) => {
  //               this.store.dispatch(AuthActions.IsLoading({ payload: false }));

  //               if (resData.succeeded === true) {
  //                 return this.store.dispatch(
  //                   AuthActions.SaveSystenSetting({
  //                     payload: resData,
  //                   })
  //                 );
  //               } else {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openSnackBar(
  //                   notification,
  //                   'flwmn-notification-error'
  //                 );

  //                 this.store.dispatch({
  //                   type: '[Auth] Failed To Get System Setting',
  //                 });

  //                 return resData;
  //               }
  //             }),
  //             catchError((errorRes) => {
  //               return this.handleCatchError(
  //                 errorRes,
  //                 `[Auth][CatchError] Failed To Get System Setting ${errorRes.message}`
  //               );
  //             })
  //           );
  //       }),
  //       share()
  //     );
  //   },
  //   { dispatch: false }
  // );

  // updateSystemSetting$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(AuthActions.UpdateSystemSetting),
  //       withLatestFrom(this.store.select('auth')),
  //       switchMap(([documentBrandindData, authState]) => {
  //         return this.http
  //           .post<any>(
  //             `${environment.onyxDocAuthUrl}/Brandings/updatesystemsetting/${documentBrandindData.payload.id}`,
  //             {
  //               ...documentBrandindData.payload,
  //               subscriberId: authState.user.SubscriberId,
  //               userId: authState.user.UserId,
  //             }
  //           )

  //           .pipe(
  //             map((resData) => {
  //               this.store.dispatch(AuthActions.IsLoading({ payload: false }));

  //               if (resData.succeeded === true) {
  //                 const notification: Notification = {
  //                   state: 'success',
  //                   message: 'System Setting saved successfully',
  //                 };

  //                 this.notificationService.openSnackBar(
  //                   notification,
  //                   'flwmn-notification-success'
  //                 );

  //                 this.store.dispatch({
  //                   type: '[Auth] Update System Setting Was Successful',
  //                 });
  //                 return resData;
  //               } else {
  //                 const notification: Notification = {
  //                   state: 'error',
  //                   message: resData.message || resData.messages[0],
  //                 };

  //                 this.notificationService.openSnackBar(
  //                   notification,
  //                   'flwmn-notification-error'
  //                 );

  //                 this.store.dispatch({
  //                   type: '[Auth] Failed To Update System Setting',
  //                 });

  //                 return resData;
  //               }
  //             }),
  //             catchError((errorRes) => {
  //               return this.handleCatchError(
  //                 errorRes,
  //                 `[Auth][CatchError] Failed To Update System Setting ${errorRes.message}`
  //               );
  //             })
  //           );
  //       }),
  //       share()
  //     );
  //   },
  //   { dispatch: false }
  // );

  getAuditParameter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.GetAuditParameter),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocAuthUrl}/AuditTrails/getauditparameters/${authState.user.UserId}/${authState.user.SubscriberId}`
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded) {
                  this.store.dispatch({
                    type: '[Auth] Get Audit Parameter Was Succesful',
                  });

                  return resData.entity;
                } else {
                  this.store.dispatch({
                    type: '[Auth] Failed To Get Audit Parameter',
                  });

                  return resData.entity;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Get Audit Parameter ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getRolePermissions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.GetRolePermissions),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandingData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocAuthUrl}/RolePermissions/getall/${authState.user.SubscriberId}/${documentBrandingData.payload.skip}/${documentBrandingData.payload.take}`
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  return this.store.dispatch(
                    AuthActions.SaveRolePermissions({
                      payload: resData,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Get Role Permissions',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Get Role Permissions ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  contactUs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ContactUs),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/Website/contactusmessage`,
            {
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Contact us message sent successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Send Contact Us Message',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Send Contact Us Message ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  joinWaitlist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.JoinWaitlist),
      withLatestFrom(this.store.select('auth')),
      switchMap(([documentData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocAuthUrl}/Website/joinwaitlist`,
            {
              ...documentData.payload,
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return {
                  type: '[Document] Join Waitlist Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Document] Failed To Join Waitlist',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Join Waitlist ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getWaitlistProductType$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.GetWaitlistProductType),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandingData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocAuthUrl}/Website/getwaitlistproducttype`
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  return this.store.dispatch(
                    AuthActions.SaveWaitlistProductType({
                      payload: resData,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Get Waitlist Product Type',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Get Waitlist Product Type ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getUserProfileDetailsById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.GetUserProfileDetailsById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([documentBrandingData, authState]) => {
          return this.http
            .get<any>(
              `${environment.onyxDocAuthUrl}/Users/getbyid/${authState?.user?.UserId}/${authState?.user?.SubscriberId}/${authState?.user?.UserId}`
            )

            .pipe(
              map((resData) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    AuthActions.SaveUserProfileDetailsById({
                      payload: resData.entity,
                    })
                  );

                  return resData.entity;
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Auth] Failed To Get User Profile Details By Id',
                  });

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Get User Profile Details By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  sendVerificationEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.SendVerificationEmail),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Subscribers/sendverificationemail`,
            authData.payload
          )

          .pipe(
            map((resData) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Auth] Send Verification Email Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Send Verification Email',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Send Verification Email ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  acceptDevice$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.AcceptDevice),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/DeviceManagement/acceptdevice`,
              {
                userId: authData.payload.userId,
                guid: authData.payload.guid,
                time: authData.payload.time,
              },
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                return resData;
              }),

              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Accept Device ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  rejectDevice$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.RejectDevice),
        withLatestFrom(this.store.select('auth')),
        switchMap(([authData, authState]) => {
          return this.http
            .post<any>(
              `${environment.onyxDocAuthUrl}/DeviceManagement/rejectdevice`,
              {
                userId: authData.payload.userId,
                guid: authData.payload.guid,
                time: authData.payload.time,
              },
              {
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                if (resData.succeeded === true) {
                  // this.router.navigate([`/login`]);

                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  // return {
                  //   type: '[Auth] Reject Device Was Successful',
                  // };
                } else {
                  // const notification: Notification = {
                  //   state: 'error',
                  //   message: resData.message || resData.messages[0],
                  // };
                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-error'
                  // );
                  // return {
                  //   type: '[Auth] Failed To Reject Device',
                  // };
                }

                return resData;
              }),

              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Auth][CatchError] Failed To Reject Device ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  confirmDeviceStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.ConfirmDeviceStatus),
      switchMap((authData) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/DeviceManagement/confirmdevicestatus`,
            {
              deviceGuid: authData.payload.deviceGuid,
            },
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              if (resData.isSuccess === true) {
                // this.store.dispatch(
                //   AuthActions.ToVerifyDevice({
                //     payload: {
                //       deviceGuid: authData.payload.deviceGuid,
                //       verifyStatus: false,
                //     },
                //   })
                // );

                if (
                  resData.isDeviceVerified &&
                  resData.deviceVerificationStatus ===
                    ConfirmDeviceStatusEnum.Accepted
                ) {
                  // const accessToken = resData.token.accessToken;
                  // const userToken = resData.token.userToken;
                  // const exp =
                  //   resData.token.expiresIn * 60000 + new Date().getTime();
                  // const refreshToken = resData.token.refreshToken;
                  // const data = { accessToken, userToken, exp, refreshToken };

                  // localStorage.setItem('OnyxDoc', JSON.stringify(data));

                  // const decoded_token = this.parseJwt(userToken);

                  // const user: any = {
                  //   ...JSON.parse(decoded_token.UserEntity),
                  //   ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
                  // };

                  // this.document.body.classList.remove('flwmn-default-theme');

                  // const user_branding: any = decoded_token?.Branding
                  //   ? JSON.parse(decoded_token?.Branding)
                  //   : null; //coming

                  // if (decoded_token.RolePermissions !== undefined) {
                  //   this.formatPermissions(
                  //     user,
                  //     JSON.parse(decoded_token.RolePermissions),
                  //     decoded_token.SubscriptionStatus
                  //   );
                  // }

                  // this.store.dispatch(
                  //   AuthActions.IsLoading({ payload: false })
                  // );

                  // this.store.dispatch(
                  //   AuthActions.AuthenticateSuccess({
                  //     payload: {
                  //       user: user,
                  //       permissionsArray: this.permissionsArray,
                  //       branding: user_branding,
                  //     },
                  //   })
                  // );

                  // this.router.navigate(['/app/dashboard']);

                  this.store.dispatch(
                    AuthActions.ToVerifyDevice({
                      payload: {
                        deviceGuid: authData.payload.deviceGuid,
                        verifyStatus: false,
                      },
                    })
                  );
                }

                this.store.dispatch(
                  AuthActions.SaveConfirmDeviceStatus({
                    payload: {
                      verifyDeviceStatus: resData,
                    },
                  })
                );

                return this.loginResponse(resData, authData);

                // return AuthActions.SaveConfirmDeviceStatus({
                //   payload: {
                //     verifyDeviceStatus: resData,
                //   },
                // });
              } else {
                return {
                  type: `[Auth] Failed To Confirm Device Status`,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Confirm Device Status ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getDevicesById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.GetDevicesById),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/DeviceManagement/devicesbyid/${authState.user.SubscriberId}/${authState.user.UserId}`
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                // const notification: Notification = {
                //   state: 'success',
                //   message: resData.message || resData.messages[0],
                // };
                // this.notificationService.openNotification(
                //   notification,
                //   'flwmn-notification-success'
                // );

                this.store.dispatch(
                  AuthActions.SaveDevicesById({
                    payload: resData.entity,
                  })
                );

                return {
                  type: '[Auth] Get All Devices By Id Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Get All Devices By Id Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Get All Devices By Id Failed ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  signOutAllDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SignOutAllDevices),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/DeviceManagement/signoutdevices`,
            {
              userId: authData.payload.userId,
              deviceGuids: authData.payload.devicesGuids,
            }
            // {
            //   context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            // }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Auth] Device Signed Out Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Sign Out Device',
                };
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Sign Out Device ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  signOutDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SignOutDevice),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/DeviceManagement/signoutdevice`,
            {
              guid: authData.payload.guid,
              userId: authData.payload.userId,
            }
            // {
            //   context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            // }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                // this.router.navigate([`/login`]);

                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                window.location.reload();

                return {
                  type: '[Auth] Device Signed Out Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Sign Out Device',
                };
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Sign Out Device ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  verifyOtpLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.OtpLogin),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/verifyotp`,
            {
              browser: authData.payload.browser,
              ipAddress: authData.payload.ipAddress,
              deviceName: authData.payload.deviceName,
              twoFAVerificationType: authData.payload.twoFAVerificationType, // or any 2FA type defined in the response
              otp: authData.payload.otp, // This will be supplied by the user
              email: authData.payload.email,
            },

            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.isSuccess === true && resData.token.accessToken) {
                // const accessToken = resData.token.accessToken;
                // const userToken = resData.token.userToken;
                // const exp =
                //   resData.token.expiresIn * 60000 + new Date().getTime();
                // const refreshToken = resData.token.refreshToken;
                // const data = { accessToken, userToken, exp, refreshToken };

                // localStorage.setItem('OnyxDoc', JSON.stringify(data));

                // const decoded_token = this.parseJwt(userToken);

                // const user: any = {
                //   ...JSON.parse(decoded_token.UserEntity),
                //   ApiClientId: JSON.parse(decoded_token?.ApiClient)?.ClientId,
                // };

                // this.document.body.classList.remove('flwmn-default-theme');

                // const user_branding: any = decoded_token?.Branding
                //   ? JSON.parse(decoded_token?.Branding)
                //   : null; //coming

                // if (decoded_token.RolePermissions !== undefined) {
                //   this.formatPermissions(
                //     user,
                //     JSON.parse(decoded_token.RolePermissions),
                //     decoded_token.SubscriptionStatus
                //   );
                // }

                // this.store.dispatch(AuthActions.IsLoading({ payload: false }));

                // this.store.dispatch(
                //   AuthActions.AuthenticateSuccess({
                //     payload: {
                //       user: user,
                //       permissionsArray: this.permissionsArray,
                //       branding: user_branding,
                //     },
                //   })
                // );

                // this.router.navigate(['/app/dashboard']);
                this.dialog.closeAll();

                return this.loginResponse(resData, authData);

                // return {
                //   type: '[Auth] Otp Login Verified Successfully',
                // };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch({
                  type: '[Auth] Failed To Verify Otp Login',
                });

                this.store.dispatch(
                  AuthActions.GetUserTwoFADetails({
                    payload: {
                      email: authData.payload.email,
                    },
                  })
                );

                // return resData.succeeded;

                return {
                  type: '[Auth] Failed To Verify Otp Login',
                };
              }
            }),

            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError] Failed To Verify Otp Login ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  onSendOtpToEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SendOtpToEmail),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/sendotptoemail`,
            {
              email: authData.payload.email,
            },

            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Auth] Otp Sent To Email Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Auth] Failed To Send Otp To Email ',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Auth][CatchError]  Failed To Send Otp To Email`
              );
            })
          );
      })
    )
  );

  getTwoFADetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.GetUserTwoFADetails),
      withLatestFrom(this.store.select('auth')),
      switchMap(([authData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/TwoFactorAuthentication/userstwofadetails/${authData.payload.email}`,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(AuthActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return AuthActions.SaveUserTwoFADetails({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return AuthActions.SaveUserTwoFADetails({
                  payload: null,
                });

                // return {
                //   type: '[User] Failed To Get User By Email ',
                // };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User][CatchError] Failed To Get User Two FA Details ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}

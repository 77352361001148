import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
// import { FLWMN_SHEET_DATA, FlwmnSheetRef } from 'src/app/@core/shared/sheet';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { EditorModeEnum } from 'src/app/@core/enums';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { EditorEffects } from 'src/app/@core/stores/editor/editor.effects';
import { EditorMode } from 'src/app/@core/types';
import {
  MAT_DIALOG_DATA,
  // MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
// import * as recipientSignatureActions from 'src/app/@core/stores/recipient-signature/recipient-signature.actions';
import * as editorSelector from 'src/app/@core/stores/editor/editor.selectors';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as UserActions from 'src/app/@core/stores/user/user.actions';
import * as EditorActions from 'src/app/@core/stores/editor/editor.actions';
import { Notification } from 'src/app/@core/interfaces';
import { ApplySignatureComponent } from '../add-signature/apply-signature/apply-signature.component';
import { DeleteSignatureComponent } from '../add-signature/delete-signature/delete-signature.component';

@Component({
  selector: 'app-add-initials',
  templateUrl: './add-initials.component.html',
  styleUrls: ['./add-initials.component.scss'],
})
export class AddInitialsComponent implements OnInit, AfterViewInit, OnDestroy {
  initialsBase64!: string | null;
  option: 'type' | 'draw' | 'saved' | 'upload' = 'type';
  savedOption: 'default' | 'others' = 'default';
  logoPreview!: string | null;
  ctx: any;
  initialsCanvas: any;
  initialsCanvasContext: any;
  fontList = [
    { name: 'Comforter Brush', value: "'Comforter Brush', cursive" },
    { name: 'Italianno', value: "'Italianno', cursive" },
    { name: 'Edwardian Script ITC', value: 'Edwardian Script ITC' },
    { name: 'Brush Script MT', value: 'Brush Script MT' },
    { name: 'Freestyle Script', value: 'Freestyle Script' },
    { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
  ];
  allThemes!: any;
  selectedTheme: any = 'theme8';
  themeColors: any = {
    theme1: '#4e33ff',
    theme2: '#fb8500',
    theme3: '#be0424',
    theme4: '#906e18',
    theme5: '#208b3a',
    theme6: '#094cf4',
    theme7: '#6c27a7',
    theme8: '#222222',
  };
  state: any;
  points: any[] = [];
  allDrawingPoints: any[] = [];
  beginPoint: any = null;
  lineWidth = 4.5;
  strokeStyle = this.themeColors[this.selectedTheme];
  shadowColor = this.themeColors[this.selectedTheme];
  shadowBlur = this.lineWidth / 4;
  lineJoin = 'round';
  lineCap = 'round';
  fontSize = '60px';
  font = "'Comforter Brush', cursive";

  userSelectorObservable: Observable<any>;
  loggedInUsersSignature: string | null = null;
  loggedInUsersSignatureIsLoading: boolean = false;
  textContext: CanvasRenderingContext2D | null;
  editorModeEnum = EditorModeEnum;
  showSavedSignature: boolean = true;
  showWaterMark: boolean = false;
  editorMode: string;
  signerFullName: string = '';
  subscriberId: number;
  userId: string | null = null;
  signatureCount: number = 0;
  signatureList: any[] = [];
  defaultSignature: any | null = null;
  // showToggle: boolean = true;
  isNonFlowmonoUser: boolean;
  userEmail: string;

  qrCodeValue = '';

  signatureInitialTypeList: any[] = [];

  loading$: Observable<boolean>;
  // isSaveSignature: boolean = true;

  currentTab: number = 0;
  skip: number = 0;

  signType: any;

  checkFirstName: boolean = false;
  disableFirstName: boolean = false;
  checkLastName: boolean = false;
  disableLastName: boolean = false;

  checkTime: boolean = false;
  disableTime: boolean = false;
  checkDate: boolean = false;
  disableDate: boolean = false;
  checkSignedBy: boolean = false;
  disableSignedBy: boolean = false;

  scannedLink: string | null = null;
  applyToAllControls: boolean = false;

  private subscription: Subscription = new Subscription();

  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('mediaContainer', { static: false }) mediaContainer: ElementRef;
  @ViewChild('documentUpload', { static: false }) documentUpload: ElementRef;
  // @ViewChild('signatureCanvas', { static: true })
  // signatureCanvas: ElementRef<HTMLCanvasElement>;

  mimeType: string;
  checked: boolean = false;

  defaultChecked: boolean = false;

  switchView: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      editorMode: EditorMode;
      showSavedSignature: boolean;
      recipientEmail: string;
      fullName: string;
      initials: string;
      email: string;
      signatureItem: any | null;
      isEditor: boolean;
      fontSize: string;
      subscriberId: any | null;
    },
    // private sheetRef: FlwmnSheetRef<AddInitialsComponent>,
    private matDialogRef: MatDialogRef<AddInitialsComponent>,
    public store: Store<fromApp.AppState>,
    private editorEffects: EditorEffects,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    // this.fontSize = data.fontSize;
  }

  ngOnInit(): void {
    this.showSavedSignature = this.data?.showSavedSignature ?? true;

    this.editorMode = this.editorModeEnum.Internal;

    this.userEmail = this.data.email || this.data.recipientEmail;

    // this.getInitialsList(100);

    this.listenToDecodeHashForSubscriberId();

    this.getUserByEmail();

    this.listenToGetUserByEmailEffect();

    this.listenToGetExternalUserByEmailEffect();

    this.listenToGetLoggedInUserByEditor();

    this.getInitialsListA();

    // this.listenToGetActiveRecipientSignatures();
  }

  ngAfterViewInit() {
    if (this.option === 'draw') {
      // this.eSignature();
      this.onSign();
    }

    // this.getInitialsList()
    // (this.input.nativeElement as HTMLElement).style.fontFamily = this.font;
  }

  onWindowResize(event: any) {
    if (this.initialsCanvas && this.mediaContainer) {
      this.initialsCanvas.width = (
        this.mediaContainer.nativeElement as HTMLElement
      ).getBoundingClientRect().width;
    }
  }

  getSavedSignature() {
    if (this.data?.editorMode === this.editorModeEnum.Internal) {
      this.onGetLoggedInUsersSignature();
    } else if (this.data?.editorMode === this.editorModeEnum.External) {
      this.getUserByEmail();
    }
  }

  onGetLoggedInUsersSignature() {
    this.loggedInUsersSignatureIsLoading = true;

    this.userSelectorObservable = this.store.pipe(
      select(authSelectors.getUser)
    );

    // this.getUserDetails(this.loggedInUser.UserId);

    this.listenToGetUserSelector();
  }

  listenToGetUserSelector() {
    this.subscription.add(
      this.store.pipe(select(authSelectors.getUser)).subscribe((resData) => {
        if (resData) {
          this.getUserDetails(resData.UserId);
        }
      })
    );
  }

  getUserDetails(id: string) {
    this.store.dispatch(UserActions.GetUserById({ payload: { id: id } }));
  }

  getUserByEmail() {
    if (this.data?.recipientEmail) {
      this.loggedInUsersSignatureIsLoading = true;

      this.store.dispatch(
        EditorActions.GetUserByEmail({
          payload: {
            email: this.data?.recipientEmail,
            userId: null,
          },
        })
      );
    } else {
      this.loggedInUsersSignatureIsLoading = false;
    }
  }

  listenToGetUserByEmailEffect() {
    this.subscription.add(
      this.editorEffects.getUserByEmail$.subscribe((res: any) => {
        if (
          res?.resData?.entity !== null &&
          res?.resData?.entity !== undefined &&
          res?.resData?.succeeded === true
        ) {
          this.loggedInUsersSignature = res?.resData?.entity?.signature;
          this.editorMode = this.editorModeEnum.Internal;
          this.signerFullName =
            res?.resData?.entity?.firstName[0].toUpperCase() +
            res?.resData?.entity?.firstName.slice(1) +
            ' ' +
            res?.resData?.entity?.lastName[0].toUpperCase() +
            res?.resData?.entity?.lastName.slice(1);

          this.data = {
            ...this.data,
            fullName: this.signerFullName,
            initials:
              res?.resData?.entity?.firstName[0].toUpperCase() +
              '.' +
              res?.resData?.entity?.lastName[0].toUpperCase(),
          };

          this.isNonFlowmonoUser = false;
          // this.initialsBase64 = this.loggedInUsersSignature;
        } else {
          this.getExternalUserByEmail();
          this.editorMode = this.editorModeEnum.External;
        }

        this.loggedInUsersSignatureIsLoading = false;
      })
    );
  }

  getExternalUserByEmail() {
    if (this.data?.recipientEmail) {
      this.store.dispatch(
        EditorActions.GetExternalUserByEmail({
          payload: {
            email: this.data?.recipientEmail,
          },
        })
      );
    }
  }

  listenToGetExternalUserByEmailEffect() {
    this.subscription.add(
      this.editorEffects.getExternalUserByEmail$.subscribe((res: any) => {
        if (
          res?.entity !== null &&
          res?.entity !== undefined &&
          res?.succeeded === true
        ) {
          this.loggedInUsersSignature = res.entity.signature;
          this.signerFullName =
            res?.entity?.firstName[0].toUpperCase() +
            res?.entity?.firstName.slice(1) +
            ' ' +
            res?.entity?.lastName[0].toUpperCase() +
            res?.entity?.lastName.slice(1);
          // this.initialsBase64 = this.loggedInUsersSignature;
          this.data = {
            ...this.data,
            fullName: this.signerFullName,
            initials:
              res?.entity?.firstName[0].toUpperCase() +
              '.' +
              res?.entity?.lastName[0].toUpperCase(),
          };

          this.isNonFlowmonoUser = true;
        }

        this.loggedInUsersSignatureIsLoading = false;
      })
    );
  }

  listenToDecodeHashForSubscriberId() {
    // decodeUrlHash

    this.subscription.add(
      this.store
        .pipe(select(editorSelector.getSavedDecodeHash))
        .subscribe((res) => {
          if (res !== null) {
            this.subscriberId = res.signingRequest.subscriberId;
            this.userId = res.signingRequest.userId;
            this.showWaterMark =
              res.signingRequest.documents[0].allowTimeStampOnSignatureControl;
            // this.showWaterMark = true;
            this.setSignatureConfigurationExtras(res.subscriber);
          }
        })
    );
  }

  setSignatureConfigurationExtras(subscriber: any) {
    const {
      signatureDate,
      signatureFirstName,
      signatureLastName,
      signatureSignedBy,
      signatureTime,
    } = subscriber;
    if (subscriber && subscriber !== null) {
      this.checkFirstName = signatureFirstName === 2 ? true : false;
      this.disableFirstName = signatureFirstName === 3 ? true : false;

      this.checkLastName = signatureLastName === 2 ? true : false;
      this.disableLastName = signatureLastName === 3 ? true : false;

      this.checkDate = signatureDate === 2 ? true : false;
      this.disableDate = signatureDate === 3 ? true : false;

      this.checkTime = signatureTime === 2 ? true : false;
      this.disableTime = signatureTime === 3 ? true : false;

      this.checkSignedBy = signatureSignedBy === 2 ? true : false;
      this.disableSignedBy = signatureSignedBy === 3 ? true : false;
    }
  }

  listenToGetLoggedInUserByEditor() {
    this.subscription.add(
      this.store
        .select(editorSelector.getDocumentsBySigningRequestId)
        .subscribe((resData: any) => {
          if (resData) {
            const document = resData[0]?.document;
            // const signType = this.route.snapshot.queryParams.signType;

            if (this.signType === 'self') {
              this.signerFullName = document.createdByName;
              this.showWaterMark = document.allowTimeStampOnSignatureControl;
              this.userEmail = document.email;
              this.data = {
                ...this.data,
                fullName: document.createdByName,
                initials:
                  this.signerFullName.split(' ')[0].charAt(0).toUpperCase() +
                  '.' +
                  this.signerFullName.split(' ')[1].charAt(0).toUpperCase(),
              };
            }
          }
        })
    );
  }

  hasDrawing(canvas: any): boolean {
    if (!canvas) {
      return false;
    }

    const context = canvas.getContext('2d');
    if (!context) {
      return false;
    }

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] !== 0) {
        // Check the alpha channel
        return true;
      }
    }
    return false;
  }

  onSign() {
    this.initialsCanvas = document.getElementById(
      'sig-canvas'
    ) as HTMLCanvasElement;

    this.initialsCanvas.width = (
      this.mediaContainer.nativeElement as HTMLElement
    ).getBoundingClientRect().width;

    this.initialsCanvas.height = (
      this.mediaContainer.nativeElement as HTMLElement
    ).getBoundingClientRect().height;

    this.initialsCanvasContext = this.initialsCanvas.getContext('2d');
    this.state = {
      mousedown: false,
    };

    this.initialsCanvas.addEventListener(
      'mousedown',
      this.handleWritingStart.bind(this)
    );
    this.initialsCanvas.addEventListener(
      'mousemove',
      this.handleWritingInProgress.bind(this)
    );
    this.initialsCanvas.addEventListener(
      'mouseup',
      this.handleDrawingEnd.bind(this)
    );
    this.initialsCanvas.addEventListener(
      'mouseout',
      this.handleDrawingEnd.bind(this)
    );

    this.initialsCanvas.addEventListener(
      'touchstart',
      this.handleWritingStart.bind(this)
    );
    this.initialsCanvas.addEventListener(
      'touchmove',
      this.handleWritingInProgress.bind(this)
    );
    this.initialsCanvas.addEventListener(
      'touchend',
      this.handleDrawingEnd.bind(this)
    );
  }

  drawLine(beginPoint: any, controlPoint: any, endPoint: any) {
    this.initialsCanvasContext.beginPath();
    this.initialsCanvasContext.moveTo(beginPoint.x, beginPoint.y);
    this.initialsCanvasContext.quadraticCurveTo(
      controlPoint.x,
      controlPoint.y,
      endPoint.x,
      endPoint.y
    );
    this.initialsCanvasContext.stroke();
    this.initialsCanvasContext.closePath();
  }

  handleWritingStart(event: any) {
    event.preventDefault();

    this.state.mousedown = true;

    const mousePos = this.getMousePositionOnCanvas(event);

    this.points.push({ x: mousePos.x, y: mousePos.y });
    this.beginPoint = { x: mousePos.x, y: mousePos.y };
    this.initialsCanvasContext.lineWidth = this.lineWidth;
    this.initialsCanvasContext.strokeStyle = this.strokeStyle;
    this.initialsCanvasContext.lineJoin = this.lineJoin;
    this.initialsCanvasContext.lineCap = this.lineCap;
    this.initialsCanvasContext.shadowColor = null;
    this.initialsCanvasContext.shadowBlur = null;

    this.initialsCanvasContext.fill();
  }

  handleWritingInProgress(event: any) {
    event.preventDefault();

    if (!this.state.mousedown) return;

    if (this.state.mousedown) {
      const mousePos = this.getMousePositionOnCanvas(event);
      this.points.push({ x: mousePos.x, y: mousePos.y });

      if (this.points.length > 3) {
        const lastTwoPoints = this.points.slice(-2);
        const controlPoint = lastTwoPoints[0];
        const endPoint = {
          x: (lastTwoPoints[0].x + lastTwoPoints[1].x) / 2,
          y: (lastTwoPoints[0].y + lastTwoPoints[1].y) / 2,
        };

        this.drawLine(this.beginPoint, controlPoint, endPoint);
        this.beginPoint = endPoint;
      }
    }
  }

  handleDrawingEnd(event: any) {
    event.preventDefault();

    if (!this.state.mousedown) return;

    if (this.state.mousedown) {
      const mousePos = this.getMousePositionOnCanvas(event);
      this.points.push({ x: mousePos.x, y: mousePos.y });

      this.initialsCanvasContext.shadowColor = this.shadowColor;
      this.initialsCanvasContext.shadowBlur = this.shadowBlur;

      // this.signatureCanvasContext.stroke();

      if (this.points.length > 3) {
        const lastTwoPoints = this.points.slice(-2);
        const controlPoint = lastTwoPoints[0];
        const endPoint = lastTwoPoints[1];
        this.drawLine(this.beginPoint, controlPoint, endPoint);
      }
      this.allDrawingPoints.push(this.points);
      this.beginPoint = null;
      // isDown = false;
      this.points = [];
    }

    this.state.mousedown = false;
  }

  handleClearButtonClick(event: any) {
    event.preventDefault();

    this.clearSignature();
  }

  getMousePositionOnCanvas(event: any) {
    const clientX =
      event.clientX || (event.touches && event.touches[0]?.clientX);
    const clientY =
      event.clientY || (event.touches && event.touches[0]?.clientY);

    const canvasRect = event.target.getBoundingClientRect();
    const canvasX = clientX - canvasRect.left;
    const canvasY = clientY - canvasRect.top;

    return { x: canvasX, y: canvasY };
  }

  onType(input: any, font?: string): string | null {
    let value = null;
    if (typeof input === 'string') {
      value = input;
    } else if (input && input.target && input.target.value) {
      value = input.target.value;
    } else {
      return null;
    }

    if (font) {
      this.font = font;
    }

    // let value = null;
    // value = event.target.value;

    this.textContext = null;
    this.textContext = (
      document.getElementById('textCanvas') as HTMLCanvasElement
    ).getContext('2d')!; //Hidden canvas

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This needs to be set first so the right width can be calculated*/

    this.textContext!.canvas.width =
      this.textContext!.measureText(value).width + 12;

    this.textContext!.canvas.height = 100;

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This is set again to ensure the right font is printed*/
    this.textContext!.fillStyle = this.themeColors[this.selectedTheme];

    this.textContext!.fillText(value, 4, 55);

    // this.initialsBase64 = this.textContext!.canvas.toDataURL('image/png', 1.0);

    // this.createSvgFromCanvas(this.textContext!.canvas, value);

    if (font) {
      // return this.initialsBase64;
      return this.textContext!.canvas.toDataURL('image/png', 1.0);
    } else {
      return null;
    }
  }

  async onTypeAsync(input: any, font?: string): Promise<string | null> {
    let value = null;
    if (typeof input === 'string') {
      value = input;
    } else if (input && input.target && input.target.value) {
      value = input.target.value;
    } else {
      return null;
    }

    if (font) {
      this.font = font;
    }

    // let value = null;
    // value = event.target.value;

    this.textContext = null;
    this.textContext = (
      document.getElementById('textCanvas') as HTMLCanvasElement
    ).getContext('2d')!; //Hidden canvas

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This needs to be set first so the right width can be calculated*/

    this.textContext!.canvas.width =
      this.textContext!.measureText(value).width + 12;

    this.textContext!.canvas.height = 100;

    this.textContext!.font = `${this.fontSize} ${this.font}`; /*This is set again to ensure the right font is printed*/
    this.textContext!.fillStyle = this.themeColors[this.selectedTheme];

    this.textContext!.fillText(value, 4, 55);

    // this.initialsBase64 = this.textContext!.canvas.toDataURL('image/png', 1.0);

    // this.createSvgFromCanvas(this.textContext!.canvas, value);

    if (font) {
      // return this.initialsBase64;
      return this.textContext!.canvas.toDataURL('image/png', 1.0);
    } else {
      return null;
    }
  }

  getInitialsList(wait: number = 100) {
    const tempInitialArr: any = [];
    for (let i = 0; i < this.fontList.length; i++) {
      setTimeout(() => {
        if (this.onType(this.data.initials, this.fontList[i].name) !== null) {
          tempInitialArr.push({
            src: this.onType(this.data.initials, this.fontList[i].name),
            fontName: this.fontList[i].name,
          });
        }
      }, wait);
    }

    this.signatureInitialTypeList = tempInitialArr;
  }

  getInitialsListA(wait: number = 100) {
    const tempInitialArr: any = [];
    for (let i = 0; i < this.fontList.length; i++) {
      setTimeout(async () => {
        await document.fonts.load(`16px ${this.fontList[i].name}`);
        const src = await this.onTypeAsync(
          this.data.initials,
          this.fontList[i].name
        );

        tempInitialArr.push({
          src: src,
          fontName: this.fontList[i].name,
        });
      }, wait);
    }

    this.signatureInitialTypeList = tempInitialArr;
  }

  onDropDocument(event: any) {
    this.onUploadDocument(event, 2);
  }

  onUploadDocument(event: any, useCase = 1) {
    const file =
      useCase === 2 ? event[0] : (event.target as HTMLInputElement).files![0];

    if (file && file.size <= 1000000) {
      const reader = new FileReader();

      reader.onload = () => {
        const fullBase64String = reader.result!.toString();
        // const base64String = fullBase64String.split(',');

        this.logoPreview = fullBase64String;

        this.initialsBase64 = this.logoPreview;
        this.option = 'upload';
      };

      reader.readAsDataURL(file);
    } else {
      this.documentUpload.nativeElement.value = null;

      const notification: Notification = {
        state: 'warning',
        message: "'Max file size is 1MB'",
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-warning'
      );
    }
  }

  removeLogo() {
    this.logoPreview = null;
    this.initialsBase64 = this.logoPreview;
  }

  onSelectTheme(event: any) {
    if (this.option !== 'type') {
      this.clearSignature();
    }

    this.selectedTheme = event.target.getAttribute('data-theme');

    this.allThemes = document.querySelectorAll('.theme');

    this.allThemes.forEach((theme: HTMLElement) => {
      if (theme.classList.contains('selected')) {
        theme.classList.remove('selected');
      }
    });

    event.target.classList.add('selected');

    this.strokeStyle = this.shadowColor = this.themeColors[this.selectedTheme];

    if (this.initialsCanvasContext) {
      this.initialsCanvasContext.lineWidth = this.lineWidth;
      this.initialsCanvasContext.strokeStyle = this.strokeStyle;
      this.initialsCanvasContext.lineJoin = this.lineJoin;
      this.initialsCanvasContext.lineCap = this.lineCap;
      this.initialsCanvasContext.shadowColor = null;
      this.initialsCanvasContext.shadowBlur = null;

      // if (this.option === 'type' || this.option === 'initial') {
      //   this.onType({
      //     target: { value: this.input.nativeElement.value },
      //   });
      // }
      // this.beginPoint = { x: this.points[0].x, y: this.points[0].y };
      this.allDrawingPoints.forEach((point) => {
        this.beginPoint = { x: point[0].x, y: point[0].y };

        for (const p of point) {
          const i = point.indexOf(p);

          if (i > 2) {
            const lastTwoPoints = [point[i - 1], p];
            const controlPoint = lastTwoPoints[0];
            const endPoint = {
              x: (lastTwoPoints[0].x + lastTwoPoints[1].x) / 2,
              y: (lastTwoPoints[0].y + lastTwoPoints[1].y) / 2,
            };

            this.drawLine(this.beginPoint, controlPoint, endPoint);
            this.beginPoint = endPoint;
          }
        }
      });
    }

    this.getInitialsList();
  }

  closeSheet(initial?: any) {
    // this.matDialogRef.close({ initial, applyToAll: this.applyToAllControls });
    this.matDialogRef.close(initial);
  }

  applySignatureToAllControls() {}

  onApplyInitial(isSelected: boolean) {
    if (isSelected) {
      const dialogRef = this.dialog.open(ApplySignatureComponent, {
        data: { isSignature: false },
        disableClose: true,
        autoFocus: false,
        panelClass: ['flwmn-dialog'],
        backdropClass: 'flwmn-dialog-backdrop',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // User confirmed (Yes)
          this.applyToAllControls = isSelected;
          this.applySignatureToAllControls();
        }
      });
    }
  }

  clearSignature() {
    const dialogRef = this.dialog.open(DeleteSignatureComponent, {
      data: {},
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog'],
      backdropClass: 'flwmn-dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        /************************Signature************************/
        const context = this.initialsCanvas?.getContext('2d');
        context?.clearRect(
          0,
          0,
          this.initialsCanvas.width,
          this.initialsCanvas.height
        );
        context?.beginPath();
        // /*********************************************************/
        this.input ? (this.input.nativeElement.value = null) : '';
        this.removeLogo();
        this.allDrawingPoints = [];
      }
    });
  }

  onFullNameChange(input: any) {
    if (input && input.target && input.target.value) {
      const value = input.target.value;
      const initials =
        value.split(' ')[0].charAt(0).toUpperCase() +
        '.' +
        value.split(' ')[1].charAt(0).toUpperCase();

      this.data = { ...this.data, initials: initials, fullName: value };

      this.onType(this.data.initials);

      this.getInitialsList();
    }
  }

  onInitialChange(input: any) {
    if (input && input.target && input.target.value) {
      const value = <string>input.target.value.toUpperCase();

      this.data = { ...this.data, initials: value };
      this.onType(this.data.initials);

      this.getInitialsList();
    }
  }

  // addWaterMark() {
  //   const canvas = document.getElementById('markedCanvas') as HTMLCanvasElement;
  //   // canvas.width = markedCanvas.width * 2;
  //   const spaceToWriteText = 150;
  //   canvas.width = canvas.width * 4 + 100;
  //   const ctx = canvas.getContext('2d')!;

  //   // Space between images and the line with text
  //   const space = 10;

  //   const img1 = new Image();
  //   img1.crossOrigin = 'anonymous';
  //   img1.src = this.initialsBase64 as string;

  //   const img2 = new Image();
  //   img2.src = '/assets/logo/flowmono-logo-only.svg';

  //   // Function to draw images and text
  //   const drawImages = () => {
  //     const halfHeight = (canvas.height - 2 * space) / 2;

  //     ctx.drawImage(img1, 200, 0, 1000, halfHeight);

  //     ctx.font = `${this.fontSize} Arial`;
  //     ctx.textBaseline = 'bottom';
  //     const text = 'Signature';
  //     const textWidth = ctx.measureText(text).width;
  //     const textX = 10;
  //     const textY = halfHeight + space + 8;

  //     ctx.lineWidth = 4;
  //     const lineX = textX + 10;
  //     ctx.beginPath();
  //     ctx.moveTo(lineX, textY);
  //     ctx.lineTo(canvas.width + 100, textY);
  //     ctx.stroke();

  //     ctx.drawImage(img2, 350 - textWidth / 2, textY + 16 + space, 100, 100);

  //     const smallText = `${new Date().toDateString()} ${new Date()
  //       .toTimeString()
  //       .slice(0, 5)}`;
  //     const smallTextWidth = ctx.measureText(smallText).width;
  //     const smallTextX = (canvas.width - smallTextWidth) / 2;
  //     const smallTextY = halfHeight + space + 16;
  //     ctx.font = `${this.fontSize} Arial`;
  //     ctx.fillStyle = `${this.themeColors['theme1']}`;
  //     ctx.fillText(
  //       smallText,
  //       smallTextX + spaceToWriteText - textWidth / 2,
  //       smallTextY + 100
  //     );

  //     const fullName = `${this.signerFullName}`;
  //     const fullNameWidth = ctx.measureText(fullName).width;
  //     const fullNameX = (canvas.width - fullNameWidth) / 2;
  //     const fullNameY = halfHeight + space + 16;
  //     ctx.font = `${this.fontSize} Arial`;
  //     ctx.fillStyle = `${this.themeColors['theme1']}`;
  //     ctx.fillText(fullName, fullNameX + 150 - textWidth / 2, fullNameY + 170);

  //     const dataURL = canvas.toDataURL('image/png', 1.0);
  //     this.initialsBase64 = dataURL;
  //     // this.createSvgFromCanvas(canvas);
  //     this.closeSheet(this.initialsBase64);
  //   };

  //   img1.onload = function () {
  //     if (img2.complete) {
  //       drawImages();
  //     }
  //   };

  //   img2.onload = function () {
  //     if (img1.complete) {
  //       drawImages();
  //     }
  //   };
  // }

  addWaterMark(inputtedSignature: string = this.initialsBase64 as string) {
    const fontSize = '60px';
    const color = '#4e33ff';

    const canvas = document.getElementById('markedCanvas') as HTMLCanvasElement;

    const ctx = canvas.getContext('2d')!;

    const space = 10;

    const img1 = new Image();
    img1.crossOrigin = 'anonymous';
    img1.src = inputtedSignature as string;

    const img2 = new Image();
    img2.src = '/assets/icons/left-curve.svg';

    // Function to draw images and text
    const drawImages = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const halfHeight = (canvas.height - 2 * space) / 2;

      const imgAspectRatio = img2.width / img2.height;
      let imgWidth = 800;
      let imgHeight = imgWidth / imgAspectRatio;

      if (imgHeight > halfHeight) {
        // If the calculated height exceeds the available height, adjust the width instead
        imgHeight = halfHeight;
        imgWidth = imgHeight * imgAspectRatio;
      }

      ctx.drawImage(img2, 50, 0, imgWidth, canvas.height);

      if (this.checkSignedBy) {
        ctx.font = `${fontSize} Arial`;
        ctx.fillStyle = color;
        ctx.fillText('SignedBy: ' + this.signerFullName, 220, 50);
      }

      ctx.drawImage(img1, 150, canvas.height / 4 - 30, 800, 250);

      const name =
        this.checkFirstName && this.checkLastName
          ? this.signerFullName
          : this.checkFirstName
          ? this.signerFullName.split(' ')[0]
          : this.checkLastName
          ? this.signerFullName.split(' ')[1]
          : null;

      if (name !== null) {
        const fullName = `${name}`;
        const fullNameY = halfHeight + space + 16;
        ctx.font = `${fontSize} Arial`;
        ctx.fillStyle = color;
        ctx.fillText(fullName, 220, fullNameY + 120);
      }

      if (this.checkDate || this.checkTime) {
        const smallText =
          (this.checkDate ? `${new Date().toDateString()}, ` : '') +
          (this.checkTime ? `${new Date().toTimeString().slice(0, 5)}` : '');

        const smallTextY = halfHeight + space + 16;
        ctx.font = `${fontSize} Arial`;
        ctx.fillStyle = color;
        ctx.fillText(smallText, 220, smallTextY + 180);
      }

      const dataURL = canvas.toDataURL('image/png', 1.0);
      this.initialsBase64 = dataURL;
      this.closeSheet(this.initialsBase64);
    };

    // Load both images before drawing
    Promise.all([
      new Promise<void>((resolve) => {
        img1.onload = () => resolve();
      }),
      new Promise<void>((resolve) => {
        img2.onload = () => resolve();
      }),
    ]).then(drawImages);
  }

  onInitialTypeSelected(event: any) {
    this.option = 'type';
    this.initialsBase64 = event.value;
  }

  onDoneInitialsPad() {
    if (this.option === 'draw') {
      if (this.hasDrawing(this.initialsCanvas)) {
        this.initialsBase64 = this.initialsCanvas.toDataURL('image/png', 1.0);
      } else {
        return;
      }
    }

    if (this.initialsBase64 && this.initialsBase64 !== null) {
      this.closeSheet(this.initialsBase64);
    }
  }

  onToolOptionClicked(option: 'type' | 'draw' | 'upload') {
    this.option = option;
    this.currentTab = 0;
    if (this.option === 'draw') {
      setTimeout(() => {
        this.onSign();
      }, 500);
    }
  }

  onInitialClicked() {
    this.currentTab = 0;
    this.option = 'type';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

<ng-container *ngIf="(isLoading$ | async) === true">
  <div class="general-loader-container webapp-view">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="email-authenticator">
  <ng-container *ngIf="!continuePage">
    <div class="email-authenticator">
      <div class="d-flex flex-column gap-1">
        <h6 class="flwmn-heading-6 fw-700 mb-0">Email confirmation</h6>

        <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
          Please verify your password to confirm its you.
        </p>
      </div>

      <div matRipple class="close-icon-container" (click)="closeDialog()">
        <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
      </div>
    </div>

    <div class="email-authenticator-box">
      <div class="email-authenticator-step">
        <div class="">
          <div class="circle-item-container">
            <div class="circle-item">
              <ng-container *ngIf="item">
                <div
                  class="circle-item initial"
                  [class.odd]="
                    i % 2 !== 0 &&
                    (item.profilePicture === null || item.profilePicture === '')
                  "
                  [class.even]="
                    i % 2 === 0 &&
                    (item.profilePicture === null || item.profilePicture === '')
                  "
                >
                  <span
                    class=""
                    *ngIf="
                      item.profilePicture === null || item.profilePicture === ''
                    "
                    >{{ item.firstName + ' ' + item.lastName | nameInitials }}
                  </span>

                  <img
                    *ngIf="
                      item.profilePicture !== null && item.profilePicture !== ''
                    "
                    class="profile-picture"
                    [src]="item.profilePicture"
                    alt=""
                  />
                </div>
              </ng-container>
            </div>
          </div>

          <div class="email-status gap-1">
            <p class="flwmn-caption mb-0 fw-700">{{ item?.email }}</p>

            <p class="status"></p>
          </div>
        </div>

        <ng-template #visibleEyeIcon>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-template>

        <ng-template #hiddenEyeIcon>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.31325 9.68665L1.33325 14.6666"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.6668 1.33331L9.68677 6.31331"
              stroke="var(--flwmn-primary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-template>

        <form [formGroup]="managePasswordForm">
          <div class="input-style1 input-md mb-1">
            <label class="input-label">Enter Password</label>

            <mat-form-field appearance="outline" [style.width.%]="100">
              <input
                matInput
                formControlName="password"
                [type]="show ? 'text' : 'password'"
                placeholder="Enter your password"
              />

              <span matSuffix class="img-fluid" (click)="show = !show">
                <ng-container *ngIf="show">
                  <ng-container
                    *ngTemplateOutlet="visibleEyeIcon"
                  ></ng-container>
                </ng-container>

                <ng-container *ngIf="!show">
                  <ng-container
                    *ngTemplateOutlet="hiddenEyeIcon"
                  ></ng-container>
                </ng-container>
              </span>

              <mat-error *ngIf="managePasswordFormControls.password.invalid">
                <img
                  src="/assets/icons/error.svg"
                  alt=""
                  class="img-fluid error-icon"
                />
                {{ getErrorMessage('password') }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>

    <div class="cta-container">
      <button
        matRipple
        type="button"
        class="btn flwmn-btn-primary-outline flwmn-btn-md"
        (click)="closeDialog()"
      >
        Cancel
      </button>

      <button
        matRipple
        class="btn flwmn-btn-primary flwmn-btn-md"
        (click)="onContinue()"
        [disabled]="managePasswordForm.invalid"
      >
        Continue
      </button>
    </div>
  </ng-container>
</section>

<section id="flowmono-authenticator">
  <ng-container *ngIf="!continuePage">
    <div class="flowmono-authenticator">
      <div class="d-flex flex-column gap-1">
        <h6 class="flwmn-heading-6 fw-700 mb-0">Set up authenticator app</h6>

        <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
          Follow the listed steps to activate your authenticator app
        </p>
      </div>

      <div matRipple class="close-icon-container" (click)="closeDialog()">
        <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
      </div>
    </div>

    <div class="flowmono-authenticator-step">
      <div class="d-flex flex-column">
        <p class="flwmn-para-1 fw-700 mb-3">Steps</p>

        <div class="steps">
          <p class="flwmn-para-1 mb-0">
            1. Download Flowmono Authenticator from the
            <a
              href="https://play.google.com/store/apps/details?id=com.flowmono.auth.app"
              target="_blank"
              >Google Play Store</a
            >
            or the
            <a
              href="https://apps.apple.com/us/app/flowmono-authenticator/id6736739090"
              target="_blank"
              >iOS App Store</a
            >.
          </p>

          <p class="flwmn-para-1 mb-0">
            2. Open the Flowmono Authenticator App
          </p>

          <p class="flwmn-para-1 mb-0">3. Scan the QR code</p>
        </div>
      </div>

      <div class="qr-code-container">
        <qrcode
          [qrdata]="qrCodeValue"
          [width]="180"
          [errorCorrectionLevel]="'M'"
          [allowEmptyString]="true"
        ></qrcode>
      </div>
    </div>

    <div class="flwmn-para-1 fw-600 other-step">
      Can’t scan it?
      <span
        class="flwmn-text-primary flwmn-pointer"
        (click)="onTryAnotherMethod()"
        >Try another method</span
      >
    </div>

    <div class="cta-container">
      <button
        matRipple
        type="button"
        class="btn flwmn-btn-primary-outline flwmn-btn-md"
        (click)="closeDialog()"
      >
        Cancel
      </button>

      <button
        matRipple
        class="btn flwmn-btn-primary flwmn-btn-md"
        (click)="onContinue()"
      >
        Continue
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="continuePage">
    <div class="flowmono-authenticator">
      <div class="d-flex flex-column gap-1">
        <h6 class="flwmn-heading-6 fw-700 mb-0">Set up authenticator app</h6>

        <!-- <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
          Follow the listed steps to activate your authenticator app
        </p> -->
      </div>

      <div matRipple class="close-icon-container" (click)="closeDialog()">
        <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
      </div>
    </div>

    <p class="flwmn-para-1 fw-500 flwmn-text-neutral text-center mb-0">
      A 6 digit code was sent to your app, enter the 6 digit code below to
      activate your authenticator
    </p>

    <div class="otp-container gap-1">
      <label class="input-label">Enter passcode</label>

      <form [formGroup]="otpForm">
        <input
          type="text"
          formControlName="otp1"
          (keyup)="moveFocus($event, 'otp2', '')"
        />
        <input
          type="text"
          formControlName="otp2"
          (keyup)="moveFocus($event, 'otp3', 'otp1')"
        />
        <input
          type="text"
          formControlName="otp3"
          (keyup)="moveFocus($event, 'otp4', 'otp2')"
        />
        <input
          type="text"
          formControlName="otp4"
          (keyup)="moveFocus($event, 'otp5', 'otp3')"
        />
        <input
          type="text"
          formControlName="otp5"
          (keyup)="moveFocus($event, 'otp6', 'otp4')"
        />
        <input
          type="text"
          formControlName="otp6"
          (keyup)="moveFocus($event, '', 'otp5')"
        />
      </form>
    </div>

    <div class="flwmn-para-1 fw-600 other-step">
      Can’t scan it?
      <span
        class="flwmn-text-primary flwmn-pointer"
        (click)="onTryAnotherMethod()"
        >Try another method</span
      >
    </div>

    <div class="cta-container">
      <button
        matRipple
        type="button"
        class="btn flwmn-btn-primary-outline flwmn-btn-md"
        (click)="onBack()"
      >
        Back
      </button>

      <button
        matRipple
        class="btn flwmn-btn-primary flwmn-btn-md"
        (click)="onSubmit()"
        [disabled]="otpForm.invalid"
      >
        Verify
      </button>
    </div>
  </ng-container>
</section>

import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as TwoStepVerificationActions from 'src/app/@core/stores/two-step-verification/two-step-verification.actions';
import * as twoStepVerificationSelectors from 'src/app/@core/stores/two-step-verification/two-step-verification.selectors';

@Component({
  selector: 'app-flowmono-email-confirmation',
  templateUrl: './flowmono-email-confirmation.component.html',
  styleUrls: ['./flowmono-email-confirmation.component.scss'],
})
export class FlowmonoEmailConfirmationComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  continuePage: boolean = false;
  i: any;
  show: boolean = false;
  activityData: any[] = [];
  item: any;
  subscriberId: number;
  ownerId: string;

  managePasswordForm: FormGroup;
  @Output() emailVerified = new EventEmitter<void>();
  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<FlowmonoEmailConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.pipe(
      select(twoStepVerificationSelectors.getLoading)
    );

    this.buildPasswordForm();

    // this.listenToUserEmail();

    this.listenToGetUserByEmail();
  }

  // listenToUserEmail() {
  //   this.subscription.add(
  //     this.store
  //       .pipe(select(authSelectors.getUser))
  //       .subscribe((resData: any) => {
  //         if (resData !== null) {
  //           this.item = resData;
  //         }
  //       })
  //   );
  // }

  getUserByEmail(user: any) {
    this.store.dispatch(
      TwoStepVerificationActions.GetUserByEmail({
        payload: {
          email: user.Email,
          userId: user.UserId,
        },
      })
    );
  }

  listenToGetUserByEmail() {
    this.subscription.add(
      this.store
        .pipe(select(twoStepVerificationSelectors.getUserByEmail))
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.item = resData;
          }
        })
    );
  }

  buildPasswordForm() {
    this.managePasswordForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  get managePasswordFormControls() {
    return this.managePasswordForm?.controls;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'password' &&
      this.managePasswordFormControls.password.hasError('required')
    ) {
      return `Please enter Password`;
    } else {
      return;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onContinue() {
    this.store.dispatch(
      TwoStepVerificationActions.IsLoading({ payload: true })
    );

    if (this.managePasswordForm.valid) {
      const password = this.managePasswordForm.value.password;

      this.store.dispatch(
        TwoStepVerificationActions.VerifyEmailAddress({
          payload: {
            password: password,
          },
        })
      );

      // this.closeDialog();

      this.dialog.closeAll();
    }
  }

  onBack() {
    this.continuePage = false;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ControlModeEnum, ControlTypeEnum } from 'src/app/@core/enums';
// import { RecipientSignatureEffects } from 'src/app/@core/stores/recipient-signature/recipient-signature.effects';
import { EditorMode, ControlMode } from 'src/app/@core/types';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import { AddInitialsComponent } from 'src/app/@components/webapp/add-initials/add-initials.component';
import * as editorSelector from 'src/app/@core/stores/editor/editor.selectors';
import { FlwmnSheet } from 'src/app/@core/services/sheet.service';

@Component({
  selector: 'app-initials',
  templateUrl: './initials.component.html',
  styleUrls: ['./initials.component.scss'],
})
export class InitialsComponent implements OnInit, OnDestroy {
  currentInitials!: string;
  controlModeEnum = ControlModeEnum;
  controlValues: any;
  showWaterMark: boolean = false;
  signType: string;

  userData: any | null = null;

  recipientEmail: string;

  private touchTime = 0;
  private subscription: Subscription = new Subscription();

  @Input() id!: string;
  @Input() form!: FormGroup;
  @Input() control: any;
  @Input() editorMode: EditorMode;
  @Input() controlMode: ControlMode;
  @Input() actorMenu: boolean = false;

  @ViewChild('applyAllFeedback') applyAllFeedback: TemplateRef<any>;

  public dialogRefResponse: MatDialogRef<any>;

  constructor(
    public dialog: MatDialog,
    private elementRef: ElementRef,
    public store: Store<fromApp.AppState>,
    private sheet: FlwmnSheet,
    private route: ActivatedRoute // private recipientEffect: RecipientSignatureEffects
  ) {}

  ngOnInit(): void {
    this.signType = this.route.snapshot.queryParams.signType;

    this.setInitials();

    this.listenToGetInternalUserByEmail();

    this.listenToGetExternalUserByEmail();

    // this.listenToGetRecipientDefaultSignatures();

    // this.listenToGetRecipientSignatures();

    this.listenToGetDocumentBySigningRequestId();

    this.listenToFormValueChanges();

    this.listenToDecodeHash();
  }

  setInitials() {
    this.controlValues = JSON.parse(
      (
        this.elementRef.nativeElement as HTMLElement
      ).parentElement?.parentElement?.getAttribute('data-control') as string
    );
    this.currentInitials = this.controlValues?.value;
    this.recipientEmail = this.controlValues?.recipientEmail;
  }

  onHandleDoubleClick() {
    if (this.touchTime == 0) {
      // Set first click
      this.touchTime = new Date().getTime();
    } else {
      // Compare first click to this click and see if they occurred within double click threshold
      if (new Date().getTime() - this.touchTime < 800) {
        // Double click occurred
        this.onAddInitials();
      } else {
        // Not a double click so set as a new first click
        this.touchTime = new Date().getTime();
      }
    }
  }

  setInitialsControlByControl(applyToAll: boolean = false) {
    if (this.controlValues.name === this.control.name) {
      this.currentInitials = this.userData.initials;
      this.updateFormValues();
      this.onApplyToAllInitials(this.currentInitials, applyToAll);
    }
  }

  updateFormValues() {
    this.form.patchValue({
      [this.control.name]:
        this.currentInitials !== undefined ? this.currentInitials : null,
    });

    this.form.updateValueAndValidity();
  }

  onEditInitialsClicked() {
    if (this.userData) {
      this.onAddInitials();
    }
  }

  onAddInitials() {
    const dialogRef = this.dialog.open(AddInitialsComponent, {
      data: {
        editorMode: this.editorMode,
        recipientEmail: JSON.parse(
          (
            this.elementRef.nativeElement as HTMLElement
          ).parentElement?.parentElement?.getAttribute('data-control') as string
        )?.recipientEmail,

        fullName: this.userData.fullName,
        initials:
          this.userData.fullName.charAt(0).toUpperCase() +
          '.' +
          this.userData.fullName.split(' ')[1].charAt(0).toUpperCase(),
        isEditor: true,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.userData = {
            ...this.userData,
            initials: result,
          };

          this.setInitialsControlByControl();
        }
      })
    );
  }

  // onAddInitials() {
  //   const sheetRef = this.sheet.open(AddInitialsComponent, {
  //     data: {
  //       editorMode: this.editorMode,
  //       recipientEmail: JSON.parse(
  //         (
  //           this.elementRef.nativeElement as HTMLElement
  //         ).parentElement?.parentElement?.getAttribute('data-control') as string
  //       )?.recipientEmail,

  //       fullName: this.userData.fullName,
  //       initials:
  //         this.userData.fullName.charAt(0).toUpperCase() +
  //         '.' +
  //         this.userData.fullName.split(' ')[1].charAt(0).toUpperCase(),
  //       isEditor: true,
  //     },
  //     panelClass: ['flwmn-sheet', 'flwmn-sheet-contained'],
  //     position: 'right',
  //     width: '624px',
  //   });

  //   this.subscription.add(
  //     sheetRef.afterClosed().subscribe((result) => {
  //       if (result) {
  //         this.userData = {
  //           ...this.userData,
  //           initials: result.initial,
  //         };

  //         this.setInitialsControlByControl(result.applyToAll);
  //       }
  //     })
  //   );
  // }

  onApplyToAllInitials(value?: any, applyToAll: boolean = false) {
    const formValues = this.form.value;

    const emptyInitials = Object.keys(formValues).filter(
      (key) => key.includes('initials') && formValues[key] === null
    );
    // console.log(emptyInitials, formValues);

    if (emptyInitials.length > 0) {
      this.dialogRefResponse = this.dialog.open(this.applyAllFeedback, {
        disableClose: true,
        autoFocus: false,
        panelClass: ['flwmn-dialog', 'flwmn-dialog-sm'],
        backdropClass: 'flwmn-dialog-backdrop',
      });

      this.subscription.add(
        this.dialogRefResponse.afterClosed().subscribe((res) => {
          if (res === true) {
            this.applyToAllInitials(value);
          }
        })
      );
      // if (applyToAll) {
      //   this.applyToAllInitials(value);
      // }
    }
  }

  onResponse(response: boolean) {
    this.dialogRefResponse.close(response);
  }

  applyToAllInitials(value: any) {
    // console.log(value, this.currentInitials);

    const controlChildren = document.getElementsByTagName(
      'app-control-builder'
    );

    const dataValues: any[] = [];

    for (let i = 0; i < controlChildren.length; i++) {
      dataValues.push(
        JSON.parse(controlChildren[i].getAttribute('data-control') as string)
      );
    }

    const recipientEmail = JSON.parse(
      (
        this.elementRef.nativeElement as HTMLElement
      ).parentElement?.parentElement?.getAttribute('data-control') as string
    )?.recipientEmail;

    const matchingItemsMap = new Map<string, any>();

    dataValues.forEach((item) => {
      if (
        item.recipientEmail === recipientEmail &&
        item.type === ControlTypeEnum.initials
      ) {
        matchingItemsMap.set(item.name, item);
      }
    });

    const controls = Object.keys(this.form.controls).filter((key) =>
      key.includes('initials')
    );

    controls.forEach((control) => {
      const matchingItem = matchingItemsMap.get(control);

      if (
        matchingItem &&
        matchingItem.value === null &&
        matchingItem.type === ControlTypeEnum.initials
      ) {
        const currentControl = this.form.get(control);
        currentControl?.patchValue(this.currentInitials);
      }
    });

    this.form.updateValueAndValidity();
  }

  isCurrentRecipient(): boolean {
    const signType = this.route.snapshot.queryParams.signType;
    if (
      (this.controlValues &&
        this.controlValues.recipientEmail === this.userData.email) ||
      signType
    ) {
      return true;
    }
    return false;
  }

  updateSignatureControl() {
    if (this.currentInitials) {
      // this.form.patchValue({
      //   [this.control.name]: this.currentSignature,
      // });

      // this.form.updateValueAndValidity();
      return;
    } else {
      this.setInitials();
    }
  }

  listenToFormValueChanges() {
    this.subscription.add(
      this.form.valueChanges.subscribe((res) => {
        this.setInitials();
      })
    );
  }

  listenToGetInternalUserByEmail() {
    this.subscription.add(
      this.store
        .select(editorSelector.getInternalUserByEmail)
        .subscribe((res) => {
          if (
            res?.entity !== null &&
            res?.entity !== undefined &&
            res?.succeeded === true
          ) {
            const { email, firstName, lastName, subscriberId } = res.entity;
            this.userData = {
              ...this.userData,
              isFlowmonoUser: true,
              email: email,
              fullName: firstName + ' ' + lastName,
              subscriberId: subscriberId,
            };

            // this.getRecipientDefaultSignature(email, subscriberId);
            // this.getRecipientSignatures(email, subscriberId);
            // this.signatureBase64 = this.loggedInUsersSignature;
          }
        })
    );
  }

  listenToGetDocumentBySigningRequestId() {
    this.subscription.add(
      this.store
        .select(editorSelector.getDocumentsBySigningRequestId)
        .subscribe((res: any) => {
          if (res?.length > 0) {
            const { email } = res[0].document;
            const signType = this.route.snapshot.queryParams.signType;
            if (signType) {
              this.userData = {
                ...this.userData,
                isFlowmonoUser: true,
                fullName: res[0].document.createdByName,
                email: email,
              };

              this.showWaterMark =
                res[0].document.allowTimeStampOnSignatureControl;
            }
          }
        })
    );
  }

  listenToGetExternalUserByEmail() {
    this.subscription.add(
      this.store
        .select(editorSelector.getExternalUserByEmail)
        .subscribe((res) => {
          if (
            res?.entity !== null &&
            res?.entity !== undefined &&
            res?.succeeded === true
          ) {
            const { emailAddress, firstName, lastName } = res.entity;
            this.userData = {
              ...this.userData,
              isFlowmonoUser: false,
              fullName: firstName + ' ' + lastName,
              email: emailAddress,
            };
          }
        })
    );
  }

  listenToDecodeHash() {
    this.subscription.add(
      this.store.select(editorSelector.getSavedDecodeHash).subscribe((res) => {
        if (res !== null) {
          this.userData = {
            ...this.userData,
            firstName: res?.recipientFirstName,
            lastName: res?.recipientLastName,
            email: res?.recipientEmail,
            fullName: res?.recipientFirstName + ' ' + res?.recipientLastName,
          };
        }
      })
    );
  }

  // getRecipientDefaultSignature(email: string, subscriberId: number) {
  //   if (email) {
  //     this.store.dispatch(
  //       recipientSignatureActions.GetRecipientDefaultSignature({
  //         payload: {
  //           email: email,
  //           subscriberId: subscriberId,
  //         },
  //       })
  //     );
  //   }
  // }

  // listenToGetRecipientDefaultSignatures() {
  //   this.subscription.add(
  //     this.store
  //       .select(recipientSignatureSelectors.getRecipientDefaultSignature)
  //       .subscribe((res: any) => {
  //         if (res !== null) {
  //           // this.currentSignature = res.signature
  //           //   ? res.signature
  //           //   : this.currentSignature;
  //           this.userData = {
  //             ...this.userData,
  //             signature: res.signature,
  //             // fullName: res.fullName,
  //             subscriberId: res.subscriberId,
  //           };
  //         }
  //       })
  //   );
  // }

  // getRecipientSignatures(email: string, subscriberId: number) {
  //   if (email) {
  //     this.store.dispatch(
  //       recipientSignatureActions.GetRecipientActiveSignatures({
  //         payload: {
  //           email: email,
  //           skip: 0,
  //           take: 10,
  //           subscriberId: subscriberId,
  //         },
  //       })
  //     );
  //   }
  // }

  // listenToGetRecipientSignatures() {
  //   this.subscription.add(
  //     this.recipientEffect.getRecipientActiveSignatures$.subscribe(
  //       (res: any) => {
  //         if (res.succeeded && res.entity && res.entity.count > 0) {
  //           this.userData = {
  //             ...this.userData,
  //             flowmonoUserHasSignature: true,
  //           };
  //         } else {
  //           this.userData = {
  //             ...this.userData,
  //             flowmonoUserHasSignature: false,
  //           };
  //         }
  //       }
  //     )
  //   );
  // }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.sheet.closeAll();
  }
}

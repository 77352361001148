import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[Recipient Signature] Reset Store');

export const IsLoading = createAction(
  '[Recipient Signature] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetRecipientSignatures = createAction(
  '[Recipient Signature] Get Recipient Signatures',
  props<{
    payload: {
      email: string;
      skip: number;
      take: number;
      subscriberId?: number;
    };
  }>()
);

export const SaveRecipientSignatures = createAction(
  '[Recipient Signature] Save Recipient Signatures',
  props<{
    payload: any;
  }>()
);

export const GetRecipientActiveSignatures = createAction(
  '[Recipient Signature] Get Recipient Active Signatures',
  props<{
    payload: {
      email: string;
      skip: number;
      take: number;
      subscriberId?: number;
    };
  }>()
);

export const SaveRecipientActiveSignatures = createAction(
  '[Recipient Signature] Save Recipient Active Signatures',
  props<{
    payload: any;
  }>()
);

export const GetRecipientDefaultSignature = createAction(
  '[Recipient Signature] Get Recipient Default Signature',
  props<{
    payload: {
      email: string;
      subscriberId?: number;
    };
  }>()
);

export const SaveRecipientDefaultSignature = createAction(
  '[Recipient Signature] Save Recipient Default Signature',
  props<{
    payload: any;
  }>()
);

export const CreateRecipientSignature = createAction(
  '[Recipient Signature] Create Recipient Signature',
  props<{
    payload: {
      email: string;
      isDefaultSignature: boolean;
      initials: string;
      fullName: string;
      isFlowmonoUser: boolean;
      signature: string;
      subscriberId?: number;
      // userId?: string | null;
    };
  }>()
);

export const UpdateRecipientSignature = createAction(
  '[Recipient Signature] Update Recipient Signature',
  props<{
    payload: {
      email: string;
      isDefaultSignature: boolean;
      initials: string;
      fullName: string;
      isFlowmonoUser: boolean;
      signature: string;
      signatureGuId: string;
      subscriberId?: number;
      // userId?: string | null;
    };
  }>()
);

export const SetRecipientSignatureToDefault = createAction(
  '[Recipient Signature] Set Recipient Signature To Default Signature',
  props<{
    payload: {
      signatureGuId: string;
      email: string;
      subscriberId?: number;
    };
  }>()
);

export const ActivateRecipientSignature = createAction(
  '[Recipient Signature] Activate Recipient Signature',
  props<{
    payload: {
      signatureGuId: string;
      isFlowmonoUser: boolean;
      subscriberId?: number;
    };
  }>()
);

export const DeactivateRecipientSignature = createAction(
  '[Recipient Signature] Deactivate Recipient Signature',
  props<{
    payload: {
      signatureGuId: string;
      isFlowmonoUser: boolean;
      subscriberId?: number;
    };
  }>()
);

export const AppendRecipientsSignatureViaQrCode = createAction(
  '[Recipient Signature] Append Recipients Signature Via Qr Code',
  props<{
    payload: {
      identifier: string;
      signature: string;
      subscriberId: number;
      userId: string;
    };
  }>()
);

<ng-container *ngIf="(isLoading$ | async) === true">
  <div class="general-loader-container webapp-view">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="two-factor-authentication">
  <div class="two-factor-authentication-container">
    <div class="two-factor-authentication-table">
      <div class="two-factor-authentication-table-heading">
        <p class="flwmn-para-2 fw-700 mb-0">2 Factor Authentication</p>

        <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
          Add an extra layer of security by enabling 2-factor authentication,
          ensuring only you can access your account.
        </p>
      </div>

      <div class="two-factor-authentication-options">
        <div class="two-factor-authentication-options-heading gap-1">
          <p class="flwmn-para-2 fw-500 mb-0">Authentication methods</p>

          <p class="flwmn-para-1 fw-500 flwmn-text-neutral mb-0">
            A list of ways you can access your account
          </p>
        </div>

        <div class="two-factor-authentication-options-menu">
          <div class="two-factor-authentication-option-one">
            <div class="header">
              <app-svg
                src="assets/images/two-factor-authentication/authenticator.svg"
              ></app-svg>

              <p class="flwmn-para-2 fw-600 mb-0 flwmn-para-1">
                Flowmono Authenticator App
              </p>
            </div>

            <p
              *ngIf="!authenticatorAdded"
              (click)="onOpenAuthenticatorApp()"
              class="flwmn-para-1 mb-0 flwmn-para-1 flwmn-text-primary status"
            >
              Add authenticator app
            </p>

            <p
              *ngIf="authenticatorAdded"
              class="flwmn-para-1 mb-0 flwmn-para-1 flwmn-text-success status"
            >
              Added
            </p>

            <div
              *ngIf="!authenticatorAdded"
              class="flwmn-pointer action"
              (click)="onOpenAuthenticatorApp()"
            >
              <app-svg src="assets/icons/document/sidebar/caret.svg"></app-svg>
            </div>

            <div
              (click)="onDeleteSetup(twoFAVerificationType.AuthenticatorApp)"
              *ngIf="authenticatorAdded"
              class="flwmn-pointer action"
            >
              <app-svg
                src="assets/icons/document/context-menu/trash.svg"
                svgPathStroke="var(--flwmn-error)"
              ></app-svg>
            </div>
          </div>

          <div class="two-factor-authentication-option-one">
            <div class="header">
              <app-svg
                src="assets/images/two-factor-authentication/email.svg"
              ></app-svg>

              <p class="flwmn-para-2 fw-600 mb-0 flwmn-para-1">Email Address</p>
            </div>

            <p
              *ngIf="!emailAuthenticatorAdded"
              class="flwmn-para-1 mb-0 flwmn-para-1 flwmn-text-primary status"
              (click)="onConfirmEmail()"
            >
              Add email address
            </p>

            <p
              *ngIf="emailAuthenticatorAdded"
              class="flwmn-para-1 mb-0 flwmn-para-1 flwmn-text-success status"
            >
              Added
            </p>

            <div
              *ngIf="!emailAuthenticatorAdded"
              class="flwmn-pointer action"
              (click)="onConfirmEmail()"
            >
              <app-svg src="assets/icons/document/sidebar/caret.svg"></app-svg>
            </div>

            <div
              *ngIf="emailAuthenticatorAdded"
              class="flwmn-pointer action"
              (click)="onDeleteSetup(twoFAVerificationType.EmailAddress)"
            >
              <app-svg
                src="assets/icons/document/context-menu/trash.svg"
                svgPathStroke="var(--flwmn-error)"
              ></app-svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as TwoStepVerificationActions from 'src/app/@core/stores/two-step-verification/two-step-verification.actions';
import * as twoStepVerificationSelectors from 'src/app/@core/stores/two-step-verification/two-step-verification.selectors';

import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-flowmono-authenticator',
  templateUrl: './flowmono-authenticator.component.html',
  styleUrls: ['./flowmono-authenticator.component.scss'],
})
export class FlowmonoAuthenticatorComponent implements OnInit, OnDestroy {
  otpForm: FormGroup;
  continuePage: boolean = false;
  qrCodeValue = '';
  messages: any;
  @Output() otpVerified = new EventEmitter<boolean>();

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FlowmonoAuthenticatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.buildOtpForm();

    this.listenForOtpUrl();
  }

  buildOtpForm() {
    this.otpForm = this.fb.group({
      otp1: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp2: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp3: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp4: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp5: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp6: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
    });
  }

  onQrCodeScanned() {
    this.store.dispatch(TwoStepVerificationActions.GenerateOtpUrl());
  }

  listenForOtpUrl() {
    this.onQrCodeScanned();

    this.store
      .select(twoStepVerificationSelectors.getOtpLink)

      .subscribe((resData) => {
        if (resData) {
          this.qrCodeValue = resData;
        }
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onContinue() {
    this.continuePage = true;
  }

  onBack() {
    this.continuePage = false;
  }

  moveFocus(event: KeyboardEvent, nextField: string, previousField?: string) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;

    if (inputValue && nextField && event.key !== 'Backspace') {
      // Move focus to next field if the current field has a value and the key is not Backspace
      const nextInput = document.querySelector(
        `input[formControlName=${nextField}]`
      ) as HTMLInputElement; // Cast to HTMLInputElement
      if (nextInput) {
        nextInput.focus();
      }
    } else if (event.key === 'Backspace' && !inputValue && previousField) {
      // Move focus to the previous field if the current field is empty and the key is Backspace
      const previousInput = document.querySelector(
        `input[formControlName=${previousField}]`
      ) as HTMLInputElement; // Cast to HTMLInputElement
      if (previousInput) {
        previousInput.focus();
        previousInput.value = ''; // Optionally clear the value in the previous input
      }
    }
  }

  onSubmit() {
    this.store.dispatch(
      TwoStepVerificationActions.IsLoading({ payload: true })
    );

    if (this.otpForm.valid) {
      const otpValues = Object.values(this.otpForm.value).join('');

      this.store.dispatch(
        TwoStepVerificationActions.VerifyOtpPin({
          payload: {
            totp: otpValues,
          },
        })
      );
    }

    // this.closeDialog();
    this.dialog.closeAll();
  }

  onTryAnotherMethod() {
    this.router.navigate([
      '/app/account-settings/profile/2factor-authentication',
    ]);

    this.closeDialog();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

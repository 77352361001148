import { createReducer, on, Action } from '@ngrx/store';
import * as FlwmnApiSampleActions from './flwmn-sample-api.actions';

export interface State {
  isLoading: boolean;
  externalApplications: any[];
}

const initialState: State = {
  isLoading: false,
  externalApplications: [],
};

const flwmnApiSampleReducerInternal = createReducer(
  initialState,
  on(FlwmnApiSampleActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(FlwmnApiSampleActions.GetAllExternalApplications, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(
    FlwmnApiSampleActions.SaveAllExternalApplications,
    (state, { payload }) => ({
      ...state,
      externalApplications: payload,
      isLoading: false,
      error: null,
    })
  )
);

export function flwmnApiSampleReduce(state: State | undefined, action: Action) {
  return flwmnApiSampleReducerInternal(state, action);
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-apply-signature',
  templateUrl: './apply-signature.component.html',
  styleUrls: ['./apply-signature.component.scss'],
})
export class ApplySignatureComponent {
  isSignature: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<ApplySignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isSignature = data.isSignature;
  }

  onApply() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  // ngOnInit(): void {}
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  map,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import * as fromApp from '../app/app.reducer';
import * as UserRoleActions from './user-role.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import {
  USE_DEVELOPER_TOKEN,
  USE_ACCESS_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';
import { RoleStatusEnum } from '../../enums';

@Injectable()
export class UserRoleEffects {
  defaultUserPaginationPayload = {
    payload: {
      skip: 0,
      take: 10,
    },
  };

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(UserRoleActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.GetAllUserRoles),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userRoleData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Roles/getbysubscriberid/${
              userRoleData?.payload?.subscriberId ??
              authState?.user?.SubscriberId
            }/${userRoleData?.payload?.userId ?? authState?.user?.UserId}/${
              userRoleData.payload.skip
            }/${userRoleData.payload.take}`,
            {
              context: new HttpContext().set(
                USE_DEVELOPER_TOKEN,
                userRoleData?.payload?.subscriberId ? true : false
              ),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return UserRoleActions.SaveAllUserRoles({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserRoleActions.SaveAllUserRoles({
                  payload: {
                    roles: [],
                    count: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Get All User Roles ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.GetActiveUserRoles),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Roles/getbysubscriberidandstatus/${
              authState?.user?.SubscriberId
            }/${RoleStatusEnum.Active}/${authState?.user?.UserId}/${
              userData.payload.skip
            }/${userData.payload.take ? userData.payload.take : 100}`,
            // `${environment.onyxDocAuthUrl}/Roles/getbysubscriberidandstatus/${
            //   userData?.payload?.subscriberId ?? authState?.user?.SubscriberId
            // }/
            // ${RoleStatusEnum.Active}/
            // ${userData?.payload?.userId ?? authState?.user?.UserId}/${
            //   userData.payload.skip
            // }/${userData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return UserRoleActions.SaveActiveUserRoles({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserRoleActions.SaveActiveUserRoles({
                  payload: {
                    roles: [],
                    count: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Get Active User Roles ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getInactiveUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.GetInactiveUserRoles),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userRoleData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Roles/getbysubscriberidandstatus/${authState?.user?.SubscriberId}/${RoleStatusEnum.Inactive}/${authState?.user?.UserId}/${userRoleData.payload.skip}/${userRoleData.payload.take}`,
            // `${environment.onyxDocAuthUrl}/Roles/getbysubscriberidandstatus/${
            //   userRoleData?.payload?.subscriberId ??
            //   authState?.user?.SubscriberId
            // }/
            // ${RoleStatusEnum.Inactive}/
            // ${userRoleData?.payload?.userId ?? authState?.user?.UserId}/${
            //   userRoleData.payload.skip
            // }/${userRoleData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return UserRoleActions.SaveInactiveUserRoles({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserRoleActions.SaveInactiveUserRoles({
                  payload: {
                    roles: [],
                    count: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Get Inactive User Roles ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getRoleById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserRoleActions.GetRoleById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([userData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocAuthUrl}/Roles/getrolesandpermissionsbyroleid/${authState.user.SubscriberId}/${authState.user.UserId}/${userData.payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  UserRoleActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[User Role] Get Role By Id Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[User Role] Failed To Get Role By Id',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[User Role][CatchError] Failed To Get Role By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createRoleAndPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.CreateRoleAndPermissions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userRoleData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Roles/createroleandpermissions`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...userRoleData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  UserRoleActions.GetAllUserRoles(
                    this.defaultUserPaginationPayload
                  )
                );

                const notification: Notification = {
                  state: 'success',
                  message: `${userRoleData.payload.name} role was created succesfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/account-settings/role']);

                return {
                  type: '[User Role] Create Role And Permissions Was Succesful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Role] Failed To Create Role And Permissions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Create Role And Permissions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  editRoleAndPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.EditRoleAndPermissions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userRoleData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Roles/updateroleandpermissions`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              ...userRoleData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                this.store.dispatch(
                  UserRoleActions.GetAllUserRoles(
                    this.defaultUserPaginationPayload
                  )
                );

                const notification: Notification = {
                  state: 'success',
                  message: `${userRoleData.payload.name} role was updated succesfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.router.navigate(['/app/account-settings/role']);

                return { type: '[User Role] Edit Role Was Successful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[User Role] Failed To Edit Role And Permissions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Edit Role And Permissions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getPermissionsBySubscriptionPlanIdAndRoleAccessLevel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserRoleActions.GetPermissionsBySubscriptionPlanIdAndRoleAccessLevel
      ),
      withLatestFrom(this.store.select('auth')),
      switchMap(([subscriptionPlanData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAuthUrl}/RolePermissions/getpermissionsbysubscriptionplanidandroleaccesslevel/${subscriptionPlanData.payload.skip}/${subscriptionPlanData.payload.take}/${subscriptionPlanData.payload.roleAccessLevel}/${subscriptionPlanData.payload.subscriptionPlanId}/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return UserRoleActions.SavePermissionsBySubscriptionPlanIdAndRoleAccessLevel(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return UserRoleActions.SavePermissionsBySubscriptionPlanIdAndRoleAccessLevel(
                  {
                    payload: [],
                  }
                );
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Get Permissions By Subscription Plan Id And Role AccessLevel ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  changeRoleStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.ChangeRoleStatus),
      withLatestFrom(this.store.select('auth')),
      switchMap(([userRoleData, authState]) => {
        return this.http
          .post(
            `${environment.onyxDocAuthUrl}/Roles/changerolestatus`,
            {
              subscriberId: authState.user.SubscriberId,
              userId: authState.user.UserId,
              roleId: userRoleData.payload.roleId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                UserRoleActions.IsLoading({ payload: false })
              );

              if (userRoleData.instance === 'all') {
                this.store.dispatch(
                  UserRoleActions.GetAllUserRoles({
                    payload: {
                      skip: userRoleData.paginationPayload.skip,
                      take: userRoleData.paginationPayload.take,
                    },
                  })
                );
              } else if (userRoleData.instance === 'active') {
                this.store.dispatch(
                  UserRoleActions.GetActiveUserRoles({
                    payload: {
                      skip: userRoleData.paginationPayload.skip,
                      take: userRoleData.paginationPayload.take,
                    },
                  })
                );
              } else if (userRoleData.instance === 'inactive') {
                this.store.dispatch(
                  UserRoleActions.GetInactiveUserRoles({
                    payload: {
                      skip: userRoleData.paginationPayload.skip,
                      take: userRoleData.paginationPayload.take,
                    },
                  })
                );
              }

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: `Role status was updated successfully`,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return { type: '[User Role] Change Role Status Was Succesful' };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return { type: '[User Role] Failed To Change Role Status' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[User Role][CatchError] Failed To Change Role Status ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}

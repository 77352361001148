import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import * as FlwmnApiSampleActions from 'src/app/@core/stores/flwmn-sample-api/flwmn-sample-api.actions';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import {
  USE_ACCESS_TOKEN,
  USE_DEVELOPER_TOKEN,
} from '../../interceptors/app.interceptor.service';
// import { Router } from '@angular/router';
// import { convertToFormData } from '../../utils/helpers';
// import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
// import { of } from 'rxjs';

@Injectable()
export class FlwmnApiEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(FlwmnApiSampleActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getExternalApplicationsId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FlwmnApiSampleActions.GetAllExternalApplications),
      withLatestFrom(this.store.select('auth')),
      switchMap(([applicationData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocAuthUrl}/ExternalApplication/getbysubscriberid/${authState.user?.ApiClientId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FlwmnApiSampleActions.IsLoading({ payload: false })
              );
              if (resData.succeeded === true) {
                return FlwmnApiSampleActions.SaveAllExternalApplications({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                this.store.dispatch(
                  FlwmnApiSampleActions.SaveAllExternalApplications({
                    payload: [],
                  })
                );

                return {
                  type: '[Application Management] Failed To Get All External Applications By Subscriber Id',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Application Management][CatchError]  Failed To Get All External Applications By Subscriber Id ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  initializeSigningRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FlwmnApiSampleActions.initializeSigningRequest),
      withLatestFrom(this.store.select('auth')),
      switchMap(([generalData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAPIAsposeUrl}/ExternalApplication/initializedocumentsigningrequest`,
            generalData.payload,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                FlwmnApiSampleActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success',
                  true
                );

                return {
                  type: '[General] Create Customer Support Request Was Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );

                return {
                  type: '[General] Failed To Create Customer Support Request',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[General][CatchError] Failed To Create Customer Support Request ${errorRes.message}`
              );
            })
          );
      })
    );
  });
}

import { createAction, props } from '@ngrx/store';
import {
  SigningRequestBucketEnum,
  SigningRequestStatusEnum,
} from '../../enums';

export const ResetStore = createAction('[Signing Request] Reset Store');

export const IsLoading = createAction(
  '[Signing Request] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetSigningRequestsBySigningRequestStatus = createAction(
  '[Signing Request] Get Signing Requests By Signing Request Status',
  props<{
    payload: {
      signingRequestStatus: SigningRequestStatusEnum | null;
      skip: number;
      take: number;
      searchValue: string | null;
      filterValue: string[];
    };
  }>()
);

export const GetSigningRequestsBySigningRequestBucket = createAction(
  '[Signing Request] Get Signing Requests By Signing Request Bucket',
  props<{
    payload: {
      signingRequestBucket?: SigningRequestBucketEnum | null;
      skip?: number;
      take?: number;
      searchValue?: string | null;
      filterValue?: string[];
    };
  }>()
);

export const GetSigningRequestById = createAction(
  '[Signing Request] Get Signing Request By Id',
  props<{
    payload: {
      signingRequestId: number;
    };
  }>()
);

export const SaveSigningRequestById = createAction(
  '[Signing Request] Save Signing Request By Id',
  props<{
    payload: any;
  }>()
);

export const ResendSigningRequest = createAction(
  '[Signing Request] Resend Signing Request',
  props<{
    payload: {
      signingRequestId: number;
    };
  }>()
);

export const GetSigningRequestUserActivity = createAction(
  '[Signing Request User Activity] Get Signing Request Activity',
  props<{
    payload: {
      signingRequestGuid: number;
      skip: number;
      take: number;
    };
  }>()
);

export const SaveSigningRequestUserActivity = createAction(
  '[Signing Request User Activity] Save Signing Request Activity',
  props<{
    payload: any;
  }>()
);

export const GetSigningRequestAuditTrailDownload = createAction(
  '[Signing Request Audit Trail] Get Signing Request Audit Trail',
  props<{
    payload: {
      signingRequestId: number;
    };
  }>()
);

export const SaveSigningRequestAuditTrailDownload = createAction(
  '[Signing Request Audit Trail] Save Signing Request Audit Trail',
  props<{
    payload: any;
  }>()
);

export const GetZippedDocuments = createAction(
  '[Signing Request] Get Zipped Documents',
  props<{
    payload: {
      signingRequestId: number;
      htmlString: string;
      downloadType: number;
      subscriberId?: number;
      userId?: string;
    };
  }>()
);

export const SaveZippedDocuments = createAction(
  '[Signing Request ] Save Zipped Documents',
  props<{
    payload: any;
  }>()
);

export const DeleteDraftItem = createAction(
  '[Signing Request] Delete Signing Request Draft Item',

  props<{
    payload: {
      signingRequestId?: number;
      subscriberId?: number;
      userId?: string;
      type?: SigningRequestBucketEnum | null;
    };

    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

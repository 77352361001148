import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-signature',
  templateUrl: './delete-signature.component.html',
  styleUrls: ['./delete-signature.component.scss'],
})
export class DeleteSignatureComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteSignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  // ngOnInit(): void {}

  onDelete() {
    this.dialogRef.close('delete');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FlowmonoEmailConfirmationComponent } from './flowmono-email-confirmation/flowmono-email-confirmation.component';
import { FlowmonoAuthenticatorComponent } from './flowmono-authenticator/flowmono-authenticator.component';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as twoStepVerificationSelectors from 'src/app/@core/stores/two-step-verification/two-step-verification.selectors';
import * as TwoStepVerificationActions from 'src/app/@core/stores/two-step-verification/two-step-verification.actions';
import * as authSelector from 'src/app/@core/stores/auth/auth.selectors';
import { TwoFAVerificationType } from 'src/app/@core/enums';
// import { OtpVerificationComponent } from 'src/app/@auth/otp-verification/otp-verification.component';

@Component({
  selector: 'app-my-profile-two-factor-authentication',
  templateUrl: './my-profile-two-factor-authentication.component.html',
  styleUrls: ['./my-profile-two-factor-authentication.component.scss'],
})
export class MyProfileTwoFactorAuthenticationComponent
  implements OnInit, OnDestroy
{
  isLoading$: Observable<boolean>;
  authenticatorAdded: boolean = false;
  emailAuthenticatorAdded: boolean = false;
  item: any;
  twoFAVerificationType = TwoFAVerificationType;

  private subscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.pipe(
      select(twoStepVerificationSelectors.getLoading)
    );

    this.listenToGetUserEmail();

    this.listenToGetUserByEmail();

    // this.dialog.open(OtpVerificationComponent, {
    //   disableClose: true,
    //   autoFocus: false,
    //   panelClass: ['flwmn-dialog', 'flwmn-dialog-md'],
    //   backdropClass: 'flwmn-dialog-backdrop',
    // });
  }

  listenToGetUserEmail() {
    this.subscription.add(
      this.store
        .pipe(select(authSelector.getUser))
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.getUserByEmail(resData);
          }
        })
    );
  }

  getUserByEmail(user: any) {
    this.store.dispatch(
      TwoStepVerificationActions.IsLoading({ payload: true })
    );

    this.store.dispatch(
      TwoStepVerificationActions.GetUserByEmail({
        payload: {
          email: user.Email,
          userId: user.UserId,
        },
      })
    );
  }

  listenToGetUserByEmail() {
    this.subscription.add(
      this.store
        .pipe(select(twoStepVerificationSelectors.getUserByEmail))
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.authenticatorAdded = resData.hasAuthenticatorApp;
            this.emailAuthenticatorAdded = resData.hasEmailTwoFA;
          }
        })
    );
  }

  onDeleteSetup(type: TwoFAVerificationType) {
    this.store.dispatch(
      TwoStepVerificationActions.IsLoading({ payload: true })
    );

    this.store.dispatch(
      TwoStepVerificationActions.DeleteAuthenticatorSetup({
        payload: {
          twoFAVerificationType: type,
        },
      })
    );

    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  // onDeleteEmailSetup() {
  //   this.store.dispatch(
  //     TwoStepVerificationActions.IsLoading({ payload: true })
  //   );

  //   this.store.dispatch(TwoStepVerificationActions.DeleteAuthenticatorSetup());
  // }

  // onDeleteAuthenticatorSetup() {
  //   this.store.dispatch(
  //     TwoStepVerificationActions.IsLoading({ payload: true })
  //   );

  //   this.store.dispatch(TwoStepVerificationActions.DeleteAuthenticatorSetup());
  // }

  onOpenAuthenticatorApp() {
    this.dialog.open(FlowmonoAuthenticatorComponent, {
      disableClose: true,
      maxWidth: '100%',
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });
  }

  onConfirmEmail() {
    this.dialog.open(FlowmonoEmailConfirmationComponent, {
      disableClose: true,
      maxWidth: '100%',
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

<section id="apply-signature">
  <div class="apply-signature-header">
    <h6 class="flwmn-heading-6 fw-700 mb-0">
      Apply {{ isSignature ? 'signature' : 'initials' }}
    </h6>

    <div matRipple class="close-icon-container" (click)="closeDialog()">
      <img src="/assets/icons/modal-close-icon.svg" alt="modal close icon" />
    </div>
  </div>

  <div class="apply-signature-container">
    <div class="apply-signature-box">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0445 17.0816L13.064 17.064L13.0816 17.0445C13.198 16.9152 13.3063 16.7657 13.3842 16.566C13.454 16.3964 13.5 16.2033 13.5 16C13.5 15.7941 13.4528 15.5988 13.3815 15.4277C13.3091 15.2538 13.2081 15.096 13.0816 14.9555L13.064 14.936L13.0445 14.9184C12.904 14.7919 12.7462 14.6909 12.5723 14.6185C12.2092 14.4672 11.7908 14.4672 11.4277 14.6185C11.2538 14.6909 11.096 14.7919 10.9555 14.9184L10.936 14.936L10.9184 14.9555C10.7919 15.096 10.6909 15.2538 10.6185 15.4277C10.5472 15.5988 10.5 15.7941 10.5 16C10.5 16.2033 10.546 16.3964 10.6158 16.566C10.6937 16.7657 10.802 16.9152 10.9184 17.0445L10.936 17.064L10.9555 17.0816C11.096 17.2081 11.2538 17.3091 11.4277 17.3815C11.5988 17.4528 11.7941 17.5 12 17.5C12.2059 17.5 12.4012 17.4528 12.5723 17.3815C12.7462 17.3091 12.904 17.2081 13.0445 17.0816ZM2.5 12C2.5 6.76614 6.76614 2.5 12 2.5C17.2339 2.5 21.5 6.76614 21.5 12C21.5 17.2339 17.2339 21.5 12 21.5C6.76614 21.5 2.5 17.2339 2.5 12ZM12 6.75C11.3139 6.75 10.75 7.31386 10.75 8V13C10.75 13.6861 11.3139 14.25 12 14.25C12.6861 14.25 13.25 13.6861 13.25 13V8C13.25 7.31386 12.6861 6.75 12 6.75Z"
          fill="#D08900"
          stroke="#D08900"
        />
      </svg>
    </div>

    <p class="flwmn-para-2 text-center mb-0" *ngIf="isSignature">
      Do you want to apply this signature to all your signature fields?
    </p>

    <p class="flwmn-para-2 text-center mb-0" *ngIf="!isSignature">
      Do you want to apply this initials to all your initials fields on this
      document?
    </p>
  </div>

  <div class="cta-container">
    <button
      matRipple
      type="button"
      class="btn flwmn-btn-primary-outline flwmn-btn-md"
      (click)="closeDialog()"
    >
      No
    </button>

    <button
      matRipple
      class="btn flwmn-btn-primary flwmn-btn-md"
      (click)="onApply()"
    >
      Yes
    </button>
  </div>
</section>

import { Component, Inject } from '@angular/core';
import { FLWMN_SHEET_DATA, FlwmnSheetRef } from 'src/app/@core/shared/sheet';

@Component({
  selector: 'app-scan-qr-code',
  templateUrl: './scan-qr-code.component.html',
  styleUrls: ['./scan-qr-code.component.scss'],
})
export class ScanQrCodeComponent {
  constructor(
    private sheetRef: FlwmnSheetRef<ScanQrCodeComponent>,
    @Inject(FLWMN_SHEET_DATA)
    public data: { scannedData: string }
  ) {}

  closeDialog() {
    this.sheetRef.close();
  }
}

import { createAction, props } from '@ngrx/store';

export const IsLoading = createAction(
  '[Drive] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllExternalApplications = createAction(
  '[Application Management] Get All External Applications'
);

export const SaveAllExternalApplications = createAction(
  '[Application Management] Save All External Applications',
  props<{
    payload: any[];
  }>()
);

export const initializeSigningRequest = createAction(
  '[Signing Request] Initialize',
  props<{ payload: any }>()
);

export const initializeSigningRequestSuccess = createAction(
  '[Signing Request] Initialize Success',
  props<{ response: any }>()
);

export const initializeSigningRequestFailure = createAction(
  '[Signing Request] Initialize Failure',
  props<{ error: any }>()
);
